import ListTable from "../../../components/ListTable/ListTable";
import {Button, Card, DatePicker, Form, Input, message, Modal, Select, Table} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useForm} from "antd/es/form/Form";
import {API} from "aws-amplify";
import dayjs from "dayjs";
import TypographyCurrency from "../../../components/CurrencySelect/TypographyCurrency";
import {FormattedNumber} from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";
import ChartistGraph from "react-chartist";
import CurrencySelect from "../../../components/CurrencySelect/CurrencySelect";
import FileUploader from "../../../components/FileUploader/FileUploader";
import Draggable from "react-draggable";
import BeneficiaryModal from "../../../components/Beneficiaries/BeneficiaryModal";

const style = theme => ({
    chartistTooltip: {
        position: 'absolute',
        display: 'none',
        padding: '5px',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '3px',
        pointerEvents: 'none',
        zIndex: '100',
    },
    currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem',
            width: 52,
            height: 26,
        },
    },
    currencyLabel: {
        textAlign: 'left',
        '& > p': {
            marginBottom: -5,
            fontSize: '0.6rem',
        },
    }
})
const CurrencyElement = ({classes, currency, value}) => (
    <div className={classes.currency}>
        <div
            className={`currency-flag currency-flag-${
                currency ? currency.toLowerCase() : ''
            }`}
        />
        <div className={classes.currencyLabel}>
            <p>{currency}</p>
            <FormattedNumber
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                value={value}
            />
        </div>
    </div>
)
const CashFlowPlanner = ({app_state, classes, getColumnSearchProps}) => {

    const [cashForm] = useForm();
    const uploaderRef = useRef();
    const beneficiaryModalRef = useRef();
    const [pieChart, setPieChart] = useState({
        payout: {
            labels: [],
            series: []
        },
        receipt: {
            labels: [],
            series: []
        }
    });

    const [modalDrag, setModalDrag] = useState({
        disabled: true,
        bounds: {left: 0, top: 0, bottom: 0, right: 0}
    });
    const draggableRef = useRef(null);


    const [barChart, setBarChart] = useState({
        labels: [],
        series: []
    })

    const [componentState, setComponentState] = useState({
        payoutList: [],
        allPayoutList: [],
        currencies: [],
        activePayoutId: null,
        isBenModalOpen: false,
        isModalOpen: false,
        isLoading: false,
        isPlanReceipt: false,
        beneficiaries: [],
        allBeneficiaries: [],
        chartData: {
            highestDate: null,
            chartCurrency: 5,
            data: {
                labels: [],
                series: []
            },
            options: {
                height: '400px',
                showPoint: true,
                axisX: {
                    labelInterpolationFnc: function (value, i, j) {
                        return value;
                    },
                },
                axisY: {
                    onlyInteger: true,
                    scaleMinSpace: 20,
                },
            }

        }
    });

    const fetchPayouts = () => {
        if (app_state.current_client && app_state.current_client.id) {
            let requestBody = {
                context: 'cashplan_records',
                fields: ['*'],
                condition: {deleted: null}
            };
            const loading = message.loading("Fetching payouts. Please wait..", 0);
            setComponentState(prev => ({...prev, isLoading: true}));
            requestBody = {...requestBody, condition: {...requestBody.condition, clientID: app_state.current_client.id}}

            API.post("commons", "/fetch", {
                body: requestBody
            }).then(realResponse => {
                console.log(realResponse);
                const response = JSON.parse(JSON.stringify(realResponse));
                const {labels, series} = prepareChartData(response, componentState.chartData.chartCurrency);
                const data = response.filter(i => i.currencyID === componentState.chartData.chartCurrency);
                setComponentState(prev => ({
                    ...prev,
                    payoutList: data,
                    allPayoutList: response,
                    isLoading: false,
                    chartData: {
                        ...prev.chartData,
                        chartCurrency: componentState.chartData.chartCurrency,
                        data: {
                            labels: labels,
                            series: series,
                        },
                        options: {...prev.chartData.options, divisor: labels ? labels.length : 0}
                    }
                }));
                constructPieChart(response, 'payout');
                constructPieChart(response, 'receipt');
                constructBarChart(response);
            }).catch(err => {
                console.log(err);
                message.error("Unable to fetch the payout record. Please refresh the list to try again..");
                setComponentState(prev => ({...prev, isLoading: false}));
            }).finally(() => loading());
        }

    }

    const constructPieChart = (response, type) => {
        const today = dayjs();

        // Filter the data for dates later than today
        const filteredData = response.filter(item => dayjs(item.scheduled).isAfter(today) && item[type] != null);

        // Group the data by currencyID and sum the payouts
        const groupedData = filteredData.reduce((acc, item) => {
            if (item.currencyID && item[type]) {
                if (!acc[item.currencyID]) {
                    acc[item.currencyID] = 0;
                }
                acc[item.currencyID] += item[type];
            }
            return acc;
        }, {});


        // Prepare data for the chart
        const currencyObj = app_state.currency_list.reduce((acc, {id, iso_alpha_3}) => ({
            ...acc,
            [id]: iso_alpha_3
        }), {});
        const labels = Object.keys(groupedData).map(currencyID => `${currencyObj[currencyID]}`);
        const series = Object.values(groupedData);

        setPieChart(prev => ({...prev, [type]: {...prev[type], labels: labels, series: series}}));
    }

    const constructBarChart = (response) => {
        const labels = response.reduce((acc, current) => {
            const d = acc.find(i => i.currencyID === current.currencyID);
            if (!d) acc.push(current);
            else Object.assign(d, current);
            return acc;
        }, []).reduce((acc, {currencyID}) => {
            const iso_alpha_3 = app_state.currency_list.find(item => item.id === currencyID).iso_alpha_3;
            return [...acc, iso_alpha_3];
        }, []);
        const currenciesObj = app_state.currency_list.reduce((acc, current) => ({
            ...acc,
            [current.iso_alpha_3]: current
        }), {});
        const payoutSeries = labels.map(iso => {
            return response
                .filter(item => currenciesObj[iso].id === item.currencyID && item.payout != null)
                .reduce((acc, {payout}) => acc + payout, 0);
        });
        const receiptSeries = labels.map(iso => {
            return response
                .filter(item => currenciesObj[iso].id === item.currencyID && item.receipt != null)
                .reduce((acc, {receipt}) => acc + receipt, 0);
        });
        setBarChart(prev => ({
            ...prev,
            labels: labels,
            series: [payoutSeries, receiptSeries]
        }));
    }

    const prepareChartData = (response, chartCurrency) => {


        if (response.length) {

            const data = response.filter(i => dayjs(i.scheduled).isAfter(dayjs()));
            const currencies = [...new Set(data.map(i => i.currencyID))];
            const payout = {}, receipt = {};

            currencies.forEach(currency => {
                const createSummary = (key, field) => {
                    key[currency] = data.filter(item => item.currencyID === currency && item[field] != null)
                        .reduce((acc, item) => {
                            const month = dayjs(item.scheduled).format('MMM YYYY');
                            acc[month] = (acc[month] || 0) + item[field];
                            return acc;
                        }, {});
                };

                createSummary(payout, 'payout');
                createSummary(receipt, 'receipt');
            });

            const labels = Array.from({ length: 12 }, (_, i) => dayjs().add(i, 'month').format('MMM YYYY'));
            const fillMissingMonths = (dataObject = {}) =>
                labels.reduce((acc, month) => ({ ...acc, [month]: dataObject[month] || 0 }), {});

            const ppayout = Object.values(fillMissingMonths(payout[chartCurrency]));
            const rreceipt = Object.values(fillMissingMonths(receipt[chartCurrency]));

            return { labels, series: [ppayout, rreceipt] };
        } else {
            return {labels: [], series: []};
        }
    }

    const fetchCurrencies = async () => {
        const response = await API.post("commons", "/fetch", {
            body: {
                context: 'currencies',
                fields: ['*'],
                condition: {deleted: [0, null]}
            }
        });
        setComponentState(prev => ({...prev, currencies: response}));
    }

    const onEditBtnClick = (payoutId) => {
        const loading = message.loading(`Loading id #${payoutId}. Please wait..`, 0);
        API.post("commons", "/fetch", {
            body: {
                context: 'cashplan_records',
                condition: {id: payoutId},
                fields: ['*']
            }
        }).then(res => {
            const [d] = res;
            setComponentState(prev => ({
                ...prev,
                isModalOpen: true,
                activePayoutId: payoutId,
                isPlanReceipt: d.receipt != null && d.payout == null
            }));
            const bottle = (JSON.parse(d.attachments) || []).map(i => ({
                name: i,
                uid: i,
                status: 'done'
            }));
            uploaderRef.current.setFileList(bottle);
            cashForm.setFieldsValue({...d, scheduled: dayjs(d.scheduled)});
        }).catch(err => {
            console.log(err);
            message.error("Failed to load. Please try again..");
        }).finally(() => loading());
    }

    const handleSubmit = (payload) => {
        const loading = message.loading("Saving payout record. Please wait..", 0);
        if (!componentState.activePayoutId) {
            payload.recordCreated = dayjs(new Date()).startOf("day");
        }
        payload.clientID = app_state.current_client.id;
        payload.attachments = JSON.stringify(payload.attachments);

        const request = componentState.activePayoutId != null ? API.post("commons", "/update", {
            body: {
                context: 'cashplan_records',
                data: payload,
                condition: {id: componentState.activePayoutId}
            }
        }) : API.post("commons", "/insert", {
            body: {
                context: 'cashplan_records',
                data: payload
            }
        });
        request.then(() => {
            message.success("Payout record saved");
            setComponentState(prev => ({...prev, activePayoutId: null, isModalOpen: false, isPlanReceipt: false}));
            fetchPayouts();
        }).catch(err => {
            console.log(err);
            message.error("Unable to save payout record. Please try again..");
        }).finally(() => loading());
    }

    const buildColumns = () => {
        return [
            {
                key: 'id',
                title: 'ID',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: 'descend'
            },
            {
                key: 'scheduled',
                title: 'Scheduled On',
                dataIndex: 'scheduled',
                ...getColumnSearchProps({
                    dataIndex: 'scheduled',
                    filterInputType: 'DATE',
                    render: (text, record) => dayjs(text).format('DD/MM/YYYY')
                })
            },
            {
              key: 'beneficiary_id',
              title: 'Beneficiary',
              dataIndex: 'beneficiary_id',
              render: (text, record) => {
                  const ben = componentState.allBeneficiaries.find(i => i.id === text);
                  if (ben != null) {
                      return ben.nickname == null ? ben.ben_legal_name : ben.nickname
                  }
                  return '-'
              }
            },
            {
                key: 'payout',
                title: 'Payout',
                dataIndex: 'payout',
                render: (text, record) => {
                    const currency = app_state.currency_list.find(i => i.id === record.currencyID);
                    if (currency) {
                        return <CurrencyElement
                            currency={currency.iso_alpha_3}
                            value={text}
                            classes={classes}
                        />
                    }
                }
            },
            {
                key: 'receipt',
                title: 'Receipt',
                dataIndex: 'receipt',
                render: (text, record) => {
                    const currency = app_state.currency_list.find(i => i.id === record.currencyID);
                    if (currency) {
                        return <CurrencyElement
                            currency={currency.iso_alpha_3}
                            value={text}
                            classes={classes}
                        />
                    }
                }
            },
            {
                key: 'recordCreated',
                title: 'Created',
                dataIndex: 'recordCreated',
                ...getColumnSearchProps({
                    dataIndex: 'recordCreated',
                    filterInputType: 'DATE',
                    render: (text, record) => text != null ? dayjs(text).format('DD/MM/YYYY') : '-'
                })
            },
            {
                key: 'action',
                title: '',
                dataIndex: 'clientID',
                render: (text, record) => <div style={{display: 'flex', justifyContent: 'start', gap: 5}}>
                    <Button type={'primary'} icon={<EditOutlined/>} onClick={() => onEditBtnClick(record.id)}></Button>
                    <Button type={'primary'} icon={<DeleteOutlined/>} danger={true} onClick={() => {
                        Modal.confirm({
                            title: `Delete ?`,
                            content: `Are you sure, you want to delete this payout record #${record.id} ?`,
                            onOk: () => {
                                const loading = message.loading("Removing. Please wait..", 0);
                                API.post("commons", "/update", {
                                    body: {
                                        context: 'cashplan_records',
                                        data: {deleted: true},
                                        condition: {id: record.id}
                                    }
                                }).then(() => {
                                    fetchPayouts();
                                    message.success("Removed");
                                }).catch(err => {
                                    console.log(err);
                                    message.success("Failed");
                                }).finally(() => loading())
                            }
                        })
                    }}></Button>
                </div>
            }
        ]
    }

    const fetchBeneficiaries = () => {
        if (!app_state.current_client) return true;
        API.post("commons", "/fetch", {
            body: {
                context: 'beneficiaries',
                fields: ['*'],
                condition: {client_id: app_state.current_client.id}
            }
        }).then(res => {
            const data = res.filter(i => i.account_currency === componentState.chartData.chartCurrency);
            setComponentState(prev => ({...prev, beneficiaries: data, allBeneficiaries: res}));
        }).catch(err => {
            console.log("Unable to fetch beneficiaries", err);
        })

    }

    useEffect(() => {
        fetchPayouts();
        fetchBeneficiaries();
    }, [app_state.current_client]);

    useEffect(() => {
        const chart1 = document.querySelector('.ct-chart-tBar');
        const chart2 = document.querySelector('.ct-chart-mBar');

        [chart1, chart2].forEach((chart, index) => {
            const tooltip = document.createElement('div');
            tooltip.className = `${classes.chartistTooltip}`;
            document.body.appendChild(tooltip);

            chart.addEventListener('mouseover', (event) => {
                if (event.target.classList.contains('ct-bar')) {
                    const value = event.target.getAttribute('ct:value');
                    const meta = event.target.getAttribute('meta');
                    const index = event.target.getAttribute('series') === "0" ? 'payout' : 'receipt';

                    let str = '';
                    if  (componentState.allPayoutList.length > 0) {
                        const currency = componentState.chartData.chartCurrency;
                        const payouts = componentState.allPayoutList
                            .filter(i => i.currencyID === currency && i[index] != null && dayjs(i.scheduled).format('MM/YYYY') === meta);
                        payouts.forEach(i => {
                            str += dayjs(i.scheduled).format('DD/MM/YYYY') + ":" + i[index] + `<br />`;
                        })
                    }
                    tooltip.innerHTML = `Payout: ${value}<br />Date: ${meta}<br /> ${str}`;
                    tooltip.style.display = 'block';
                    tooltip.style.left = `${event.pageX}px`;
                    tooltip.style.top = `${event.pageY - 40}px`;
                }
            });

            chart.addEventListener('mouseout', () => {
                tooltip.style.display = 'none';
            });

            chart.addEventListener('mousemove', (event) => {
                tooltip.style.left = `${event.pageX}px`;
                tooltip.style.top = `${event.pageY - 40}px`;
            });
        })


        const pie1 = document.querySelector('.ct-pie-chart');
        const pie2 = document.querySelector('.ct-pie-chart-2');

        if (pie1 && pie2) {

            [pie1, pie2].forEach((pie, index) => {

                const legendContainer = document.getElementById(`chart-legend-${index}`);
                legendContainer.innerHTML = "";
                const series = pie.querySelectorAll('.ct-series');
                const labels = pieChart.payout.labels;

                series.forEach((serie, index) => {
                    const legendItem = document.createElement('div');
                    legendItem.classList.add('legend-item');

                    legendItem.style.display = 'flex';
                    legendItem.style.alignItems = 'center';
                    legendItem.style.marginBottom = '5px';
                    legendItem.style.marginRight = '10px';

                    const label = document.createElement('span');
                    const labelss = labels[index];
                    label.textContent = labels[index]; // Add corresponding label

                    if (labelss != null) {
                        const img = document.createElement('img');
                        img.setAttribute('src', `https://fwwportal-branding.s3.us-east-1.amazonaws.com/currencies/${labelss.toLowerCase()}.png`);
                        img.setAttribute('width', '15px');
                        img.setAttribute('height', '10px');
                        img.style.marginRight = '5px';
                        legendItem.appendChild(img)
                    }
                    legendItem.appendChild(label);
                    legendContainer.appendChild(legendItem);
                })
            })
        }
    }, [componentState.chartData.chartCurrency, pieChart.payout, pieChart.receipt]);

    const listener = {
        draw: (data) => {
            if (data.type === 'bar') {
                const xAxisLabel = data.axisX.ticks[data.index];
                data.element.attr({
                    'meta': xAxisLabel,
                    'series': data.seriesIndex
                });
                console.log(data);
            }

            if (data.type === 'label' && data.axis.units.pos === 'x') {
                data.element.empty()._node.innerHTML = data.text.length === 3 ? `
                <span class="ct-label ct-horizontal ct-end" style="width: 78px; height: 20px">
                    <img alt='currency' style="margin-right: 5px"
                     src='https://fwwportal-branding.s3.us-east-1.amazonaws.com/currencies/${(data.text || '').toLowerCase()}.png'
                     width="15px" height="10px"/> ${data.text}
                 </span>
                ` : `<span class="ct-label ct-horizontal ct-end" style="width: 78px; height: 20px">${data.text}</span>`;
            }
        }
    }

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggableRef.current.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setModalDrag(prev => ({
            ...prev,
            bounds: {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y),
            }
        }));
    };

    return <>
        <Card size={'small'} style={{marginBottom: '10px', gap: '20'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}>
                <Form.Item label={'Currency'} name={'selected_currency'} style={{ marginBottom: 0 }}>
                    <Select allowClear={false}
                            showSearch={true}
                            defaultValue={5}
                            style={{width: '250px'}}
                            optionFilterProp={'alt'}
                            options={app_state.currency_list.filter(i => i.id !== 1).map(i => ({
                                ...i,
                                label: <TypographyCurrency
                                    iso_alpha_3={i.iso_alpha_3}>[{i.iso_alpha_3}] {i.full_name}</TypographyCurrency>,
                                value: i.id,
                                alt: `${i.full_name} ${i.iso_alpha_3}`
                            }))}
                            onChange={((val, datum) => {
                                const {labels, series} = prepareChartData(componentState.allPayoutList, datum['id']);
                                const data = val != null ? componentState.allPayoutList.filter(i => i.currencyID === val) : componentState.allPayoutList;
                                setComponentState(prev => ({
                                    ...prev,
                                    payoutList: data,
                                    chartData: {
                                        ...prev.chartData,
                                        chartCurrency: datum['id'],
                                        data: {
                                            series: series,
                                            labels: labels
                                        }
                                    }
                                }));
                            })}
                            placeholder={'-- SELECT --'}></Select>
                </Form.Item>
                <div style={{ display: 'flex', alignItems: 'end', gap: 5 }}>
                    <Button type={'primary'} size={'small'} icon={<PlusOutlined/>}
                            onClick={() => setComponentState(prev => ({
                                ...prev,
                                isModalOpen: true,
                                isPlanReceipt: true,
                                activePayoutId: null
                            }))}>Add New Receipt</Button>
                    <Button type={'primary'}
                            size={'small'}
                            icon={<PlusOutlined/>}
                            onClick={() => {
                                const bene = componentState.allBeneficiaries.filter(i => i.account_currency === componentState.chartData.chartCurrency);
                                setComponentState(prev => ({...prev, isModalOpen: true,  isPlanReceipt: false, activePayoutId: null, beneficiaries: bene}))
                            }}>
                        Add New Payout
                    </Button>
                </div>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <h3 style={{textAlign: 'center'}}>FX Coverage</h3>
                    <ChartistGraph data={pieChart.receipt}
                                   className={'ct-pie-chart-2'}
                                   options={{
                                       height: 250,
                                       labelInterpolationFnc: function (value) {
                                           return value;
                                       },
                                   }} type={'Pie'}/>
                    <div id={'chart-legend-1'} style={{display: 'flex', gap: 5}}></div>
                </div>
                <div>
                    <h3 style={{textAlign: 'center'}}>FX Exposure</h3>
                    <ChartistGraph
                        style={{margin: 20, fontWeight: 600}}
                        data={barChart}
                        type="Bar"
                        className={'ct-chart-mBar'}
                        listener={listener}
                        options={{
                            height: 250,
                            showPoint: true,
                            axisX: {
                                labelInterpolationFnc: function (value, i, j) {
                                    return value;
                                },
                            },
                            axisY: {
                                onlyInteger: true,
                                scaleMinSpace: 20,
                            },
                        }}
                    />
                </div>
            </div>
        </Card>
        <Card style={{marginBottom: '10px'}} title={
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <span>Exposure Monitor</span>
                <div style={{display: "flex", gap: '5px'}}>
                    <Button type={'primary'} danger={true} size={'small'} onClick={() => fetchPayouts()}
                            icon={<ReloadOutlined/>}>Refresh List</Button>
                </div>
            </div>
        }>
            <Card title={'Currency Plan Chart'}>
            <>
                <ChartistGraph
                    key={componentState.chartData.chartCurrency}
                    style={{margin: 20, fontWeight: 600}}
                    className={'ct-chart-tBar'}
                    data={componentState.chartData.data}
                    type="Bar"
                    listener={listener}
                    options={componentState.chartData.options}
                />
            </>
            </Card>
            <Table columns={buildColumns()}
                    size="small"
                   dataSource={componentState.payoutList}
                   loading={componentState.isLoading}></Table>
            <Modal visible={componentState.isModalOpen}
                   style={{ top: 3 }}
                   title={componentState.activePayoutId ? `Update Payout #${componentState.activePayoutId} Record` : 'Add New Record'}
                   okText={
                       componentState.activePayoutId
                           ? `Update ${componentState.isPlanReceipt ? 'Receipt' : 'Payout'} Record`
                           : `Add ${componentState.isPlanReceipt ? 'Receipt' : 'Payout'} Record`
                   }
                   onCancel={() => {
                       cashForm.resetFields();
                       uploaderRef.current.clearList();
                       setComponentState(prev => ({
                           ...prev,
                           isModalOpen: false,
                           activePayoutId: null,
                           isPlanReceipt: false
                       }));
                   }}
                   onOk={() => cashForm.submit()}>
                <Form layout={'vertical'} initialValues={{ currencyID: componentState.chartData.chartCurrency, attachments: [] }} name={'payout-form'} form={cashForm} onFinish={handleSubmit}>
                    <Form.Item label={'Payment Due Date'} name={'scheduled'} normalize={(dayjsValue) =>
                        dayjsValue && dayjsValue.startOf("day").format('YYYY-MM-DD')
                    }
                               getValueProps={(value) =>
                                   value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                               }>
                        <DatePicker format={'DD/MM/YYYY'}/>
                    </Form.Item>
                    <span style={{ display: 'block', marginBottom: '10px' }}>
                        <CurrencySelect state={{ currencies: app_state.currency_list.filter(i => i.id !== 1) || [] }}
                                        rightLabel={'Beneficiary gets'}
                                        onCurrencyChange={(val, d) => {
                                            const benes = componentState.allBeneficiaries.filter(i => i.account_currency === d);
                                            setComponentState(prev => ({...prev, beneficiaries: benes }));
                                        }}
                                        validated
                                        currencyFieldName={'currencyID'}
                                        fieldName={!componentState.isPlanReceipt ? 'payout' : 'receipt'}></CurrencySelect>
                    </span>
                    {
                        !componentState.isPlanReceipt && <><Form.Item name={'beneficiary_id'} label={'Beneficiary'}>
                            <Select placeholder={' -- SELECT BENEFICIARY --'}
                                    options={(componentState.beneficiaries || []).filter(i => i.account_currency != null).map(i => {
                                        const curr = app_state.currency_list.find(k => k.id === i.account_currency);
                                        return {
                                            alt: i.nickname == null ? i.ben_legal_name : i.nickname,
                                            label: <TypographyCurrency iso_alpha_3={curr.iso_alpha_3}>{i.nickname == null ? i.ben_legal_name : i.nickname}</TypographyCurrency>,
                                            value: i.id
                                        };
                                    })}
                                    optionFilterProp={'alt'}></Select>
                        </Form.Item>
                        <Button style={{ marginBottom: '10px' }} type={'primary'} size={'small'} onClick={() => setComponentState(prev => ({...prev, isBenModalOpen: true}))}>Add Beneficiary</Button>
                        </>
                    }
                    <Form.Item name={'description'} label={'Description'}>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="attachments" getValueFromEvent={(e) => e.map(i => i.name)}>
                        <FileUploader key={componentState.activePayoutId} uploaderType={'DRAGDROP'} ref={uploaderRef}></FileUploader>
                    </Form.Item>
                    <Modal visible={componentState.isBenModalOpen}
                           width={'900px'}
                           style={{top: 10}}
                           bodyStyle={{maxHeight: '80vh', overflowY: 'scroll', padding: '10px', background: '#E0e0e0'}}
                           onCancel={() => {
                               beneficiaryModalRef.current.resetFields();
                               setComponentState(prev => ({...prev, isBenModalOpen: false}))
                           }}
                           onOk={() => {
                               beneficiaryModalRef.current.submit();
                           }}
                           modalRender={(modal) => (
                               <Draggable
                                   disabled={modalDrag.disabled}
                                   bounds={modalDrag.bounds}
                                   nodeRef={draggableRef}
                                   onStart={(event, uiData) => onStart(event, uiData)}
                               >
                                   <div ref={draggableRef}>{modal}</div>
                               </Draggable>
                           )}
                           title={
                               <div style={{ width: '100%', cursor: 'move' }}
                                    onMouseOver={() => {
                                        if (modalDrag.disabled) {
                                            setModalDrag(prev => ({...prev, disabled: false}));
                                        }
                                    }}
                                    onMouseOut={() => {
                                        setModalDrag(prev => ({...prev, disabled: true}));
                                    }}>Add New Beneficiary</div>
                           }>
                        <BeneficiaryModal clientId={app_state.current_client ? app_state.current_client.id : null}
                                          initialCurrency={5}
                                          onSubmit={(val) => {
                                              fetchBeneficiaries();
                                              setComponentState(prev => ({...prev, isBenModalOpen: false}));
                                          }}
                                          ref={beneficiaryModalRef}></BeneficiaryModal>
                    </Modal>
                </Form>
            </Modal>
        </Card>
    </>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ListTable(CashFlowPlanner)));