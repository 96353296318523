import React from 'react'
// react plugin for creating charts
import ChartistGraph from 'react-chartist'
// core components
import { API } from 'aws-amplify'
import { dailySalesChart } from 'variables/charts'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import withStyles from '@material-ui/core/styles/withStyles'
import Chip from '@material-ui/core/Chip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import RestoreIcon from '@material-ui/icons/Restore'
import Button from '../CustomButtons/Button'
import {Button as AntButton, Card, Space, Tabs as AntTabs} from 'antd'
import {currencies} from "../../redux/reducers/currencies";
import Tabs from "@material-ui/core/Tabs";
import {connect} from "react-redux";

var axios = require('axios')
const moment = require('moment')
var Chartist = require('chartist')

const styles = theme => ({
    tab: {
        minWidth: 40,
    },
    chip: {
        margin: theme.spacing.unit,
        '&:hover': {
            backgroundColor: 'rgba(3, 77, 104, 0.7)',
            color: 'white',
            fontWeight: 450,
        },
    },
    chipActive: {
        margin: theme.spacing.unit,
        backgroundColor: 'rgba(3, 77, 104, 0.7)',
        color: 'white',
        fontWeight: 450,
    },
})

class Charts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            selectedCurrency: 0,
            currency_from_id: 'AUD',
            currency_to_id: 'USD',
            duration: '1w',
            chartMaxValue: '',
            chartMinValue: '',
            error_text: '',
            currencies_list: [],

            value: 0,
            inverse: false,
            isLoading: true,

            currency_value: [
                { value: '0', label: 'AUD:USD' },
                { value: '1', label: 'AUD:EUR' },
                { value: '2', label: 'AUD:GBP' },
                { value: '3', label: 'AUD:NZD' },
                { value: '4', label: 'AUD:HKD' },
                { value: '5', label: 'AUD:SGD' },
                { value: '6', label: 'AUD:CAD' },
                { value: '7', label: 'AUD:JPY' },
                { value: '8', label: 'EUR:USD' },
                { value: '9', label: 'GBP:USD' },
                { value: '10', label: 'USD:CAD' },
                { value: '11', label: 'USD:JPY' },
            ],

            chart_title: '',
            chart_caption: '',
            chart_title_inverse: '',
            chart_caption_inverse: '',
        }
    }

    componentDidMount() {
        API.get('currencies', `/currencies/get-list-priority`)
            .then(response => {
                console.log(response);
                this.setState({
                    currencies_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

        this.fetchNewRate()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.duration !== this.state.duration) || (this.props.app_state.current_client != prevProps.app_state.current_client)) {
            this.fetchNewRate();
        }
        if (this.props.formCurrency != null
            && prevProps.formCurrency != null
            && (prevProps.formCurrency.from !== this.props.formCurrency.from
            || prevProps.formCurrency.to !== this.props.formCurrency.to)) {
            this.setState({ currency_to_id: this.props.formCurrency.to, currency_from_id: this.props.formCurrency.from });
            this.fetchNewRate();
        }
    }

    handleCustomReactSelectChange = name => value => {
        var option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }
        this.setState({
            [name]: option_value,
        })

        this.fetchNewRate()
    }

    fetchNewRate = () => {
        let url = `https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=${
            this.state.currency_to_id
        }&source=${this.state.currency_from_id}&format=1`
        axios.get(url).then(res => {
            let rate =
                res.data.quotes[
                    `${this.state.currency_from_id}${this.state.currency_to_id}`
                ];
            console.log(this.props);
            if (this.props.app_state.current_client != null) {
                rate = !this.props.app_state.current_client.rates_marketCharts ? rate - (rate * this.props.app_state.current_client.default_rate) : rate;
            }
            console.log("here modified rate", rate);
            this.setState({ chart_caption: rate })
        })

        if (
            this.state.currency_to_id &&
            this.state.currency_from_id &&
            this.state.duration
        ) {
            let data = []
            let start_date = moment()
            let end_date = moment()
            let baseCurrency = this.state.currency_from_id
            let termsCurrency = this.state.currency_to_id
            let url = ''
            let range = this.state.duration

            switch (range) {
                case '5y':
                    start_date = moment().subtract(5, 'year')
                    break
                case '1y':
                    start_date = moment().subtract(1, 'year')
                    break
                case '6m':
                    start_date = moment().subtract(6, 'month')
                    break
                case '1m':
                    start_date = moment().subtract(1, 'month')
                    break
                case '1w':
                    start_date = moment().subtract(1, 'week')
                    break
                case '1d':
                    start_date = moment().subtract(24, 'hours')
                    break
            }

            if (range === '1d') {
                url = `https://api.forexworldwide.com/hourly-fx-rate`
                axios
                    .get(url, {
                        params: {
                            termsCurrency: termsCurrency,
                            baseCurrency: baseCurrency,
                            startDate: start_date.format('YYYY-MM-DD'),
                            endDate: end_date.format('YYYY-MM-DD'),
                        },
                    })
                    .then(response => {
                        console.log(response)
                        let data = []
                        let labels = []
                        let counter = 1
                        for (let key in response.data) {
                            if (this.state.inverse) {
                                data.push(1 / response.data[key].value)
                            } else {
                                let rate = response.data[key].value;
                                if (this.props.app_state.current_client != null) {
                                    rate = !this.props.app_state.current_client.rates_marketCharts ? rate - (rate * this.props.app_state.current_client.default_rate) : rate;
                                }

                                data.push(
                                    Number.parseFloat(rate).toFixed(5)
                                )
                            }

                            if (counter % 2 == 0) {
                                let label_date = moment(
                                    response.data[key].timestamp
                                )
                                // console.log(label_date);
                                // labels.push(label_date.add(10, "hours").format("HH:mm"))
                                labels.push(`${label_date.format('HH:00')}`)
                                // labels.push(response.data[key].timestamp.slice(11,16))
                            } else {
                                labels.push('')
                            }
                            counter++
                        }

                        let data2 = []
                        this.setState({
                            chartMaxValue: Math.max(data),
                            chartMinValue: Math.min(data),
                        })
                        data2.push(data)
                        this.setState({
                            chartsData: { series: data2, labels: labels },
                        })
                        this.setState({ error_text: '' })
                    })
                    .then(() => {
                        this.setState({ data: { series: data } })
                        this.setState({ loading: false })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if (range === '1w') {
                url = `https://api.forexworldwide.com/hourly-fx-rate`
                axios
                    .get(url, {
                        params: {
                            termsCurrency: termsCurrency,
                            baseCurrency: baseCurrency,
                            startDate: start_date.format('YYYY-MM-DD'),
                            endDate: end_date.format('YYYY-MM-DD'),
                        },
                    })
                    .then(response => {
                        let data = []
                        let labels = []
                        let current_date = ''

                        let counter = 1
                        for (let key in response.data) {
                            if (counter % 5 == 0) {
                                let dateObj = new Date(
                                    response.data[key].timestamp
                                )
                                let isWeekend = dateObj.getDay()
                                let firstDay = dateObj.getDate()
                                if (isWeekend == 0 || isWeekend == 6) continue;

                                let rate = response.data[key].value;
                                if (this.props.app_state.current_client != null) {
                                    rate = !this.props.app_state.current_client.rates_marketCharts ? rate - (rate * this.props.app_state.current_client.default_rate) : rate;
                                }

                                if (this.state.inverse) {
                                    data.push(1 / rate)
                                } else {
                                    data.push(rate)
                                }
                                let date = response.data[key].timestamp.slice(
                                    0,
                                    10
                                )
                                if (current_date == date) {
                                    labels.push('')
                                } else {
                                    let label_date = moment(
                                        response.data[key].timestamp
                                    )
                                    // console.log(label_date);
                                    labels.push(label_date.format('DD MMM'))
                                    // labels.push(`${label_date.format("HH:00")}`)

                                    // labels.push(response.data[key].timestamp.slice(0,10))
                                    current_date = date
                                }
                                counter++
                            } else {
                                counter++
                            }
                        }

                        let data2 = []
                        this.setState({
                            chartMaxValue: Math.max(data),
                            chartMinValue: Math.min(data),
                        })
                        data2.push(data)
                        this.setState({
                            chartsData: { series: data2, labels: labels },
                        })
                        this.setState({ error_text: '' })
                    })
                    .then(() => {
                        this.setState({ data: { series: data } })
                        this.setState({ loading: false })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else if (range === '5y') {
                url = `https://api.forexworldwide.com/daily-fx-rate`
                axios
                    .get(url, {
                        params: {
                            termsCurrency: termsCurrency,
                            baseCurrency: baseCurrency,
                            startDate: start_date.format('YYYY-MM-DD'),
                            endDate: end_date.format('YYYY-MM-DD'),
                        },
                    })
                    .then(response => {
                        let data = []
                        let labels = []
                        let current_date = ''
                        let counter = 0
                        let y2013 = false
                        let y2014 = false
                        let y2015 = false
                        let y2016 = false
                        let y2017 = false
                        let y2018 = false
                        let y2019 = false

                        // labels.push(start_date.format('MMM YYYY'));
                        for (let key = 0; key < response.data.length; key++) {
                            let dateObj = new Date(response.data[key].date)
                            let isWeekend = dateObj.getDay()
                            let firstDay = dateObj.getDate()
                            let firstMonth = dateObj.getMonth()

                            if (isWeekend == 0 || isWeekend == 6) continue

                            let rate = response.data[key].value;
                            if (this.props.app_state.current_client != null) {
                                rate = !this.props.app_state.current_client.rates_marketCharts ? rate - (rate * this.props.app_state.current_client.default_rate) : rate;
                            }

                            if (counter % 5 == 0) {
                                if (this.state.inverse) {
                                    data.push(1 / rate)
                                } else {
                                    data.push(rate)
                                }
                                if (
                                    (firstDay == 1 ||
                                        firstDay == 2 ||
                                        firstDay == 3 ||
                                        firstDay == 4 ||
                                        firstDay == 5 ||
                                        firstDay == 6) &&
                                    firstMonth == 0
                                )
                                    labels.push(
                                        moment(
                                            response.data[key].date,
                                            'YYYY-MM-DD'
                                        ).format('MMM YYYY')
                                    )
                                else labels.push('')
                            }
                            counter++

                            // if (counter % moduler == 0) labels.push(response.data[key].date);
                            // else labels.push("");
                            // counter++;
                        }

                        let data2 = []
                        this.setState({
                            chartMaxValue: Math.max(data),
                            chartMinValue: Math.min(data),
                        })
                        data2.push(data)
                        this.setState({
                            chartsData: { series: data2, labels: labels },
                        })
                        this.setState({ error_text: '' })
                    })
                    .then(() => {
                        this.setState({ data: { series: data } })
                        this.setState({ loading: false })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                url = `https://apilayer.net/api/timeframe?access_key=a4eb7fd0501842eb4d4712cc459cae5f`
                axios
                    .get(url, {
                        params: {
                            currencies: termsCurrency,
                            source: baseCurrency,
                            start_date: start_date.format('YYYY-MM-DD'),
                            end_date: end_date.format('YYYY-MM-DD'),
                        },
                    })
                    .then(response => {
                        if (!response.data.success) {
                            this.setState({
                                error_text: response.data.error.info,
                            })
                            this.setState({ chartsData: [] })
                        } else {
                            let moduler = 7
                            if (range == '6m') moduler = 30
                            if (range == '1y') moduler = 50
                            if (range == '5y') moduler = 300
                            let data = []
                            let labels = []
                            let counter = 1

                            // labels.push(start_date.format('DD MMM'));
                            let key = `${baseCurrency}${termsCurrency}`
                            for (var item in response.data.quotes) {
                                let dateObj = new Date(item)
                                let firstDay = dateObj.getDate()
                                if (range == '1m') {
                                    if (counter % moduler == 5)
                                        labels.push(
                                            moment(item, 'YYYY-MM-DD').format(
                                                'DD MMM'
                                            )
                                        )
                                    else labels.push('')
                                } else {
                                    if (range == '6m') {
                                        if (counter % moduler == 5)
                                            labels.push(
                                                moment(
                                                    item,
                                                    'YYYY-MM-DD'
                                                ).format('MMM YYYY')
                                            )
                                        else labels.push('')
                                    } else if (firstDay == 1) {
                                        labels.push(
                                            moment(item, 'YYYY-MM-DD').format(
                                                'MMM YYYY'
                                            )
                                        )
                                    } else labels.push('')
                                }

                                let rate = response.data.quotes[item][key];
                                if (this.props.app_state.current_client != null) {
                                    rate = !this.props.app_state.current_client.rates_marketCharts ? rate - (rate * this.props.app_state.current_client.default_rate) : rate;
                                }
                                if (this.state.inverse) {
                                    data.push(
                                        1 / rate
                                    )
                                } else {
                                    data.push(rate);
                                }

                                counter++
                            }
                            let data2 = []
                            this.setState({
                                chartMaxValue: Math.max(data),
                                chartMinValue: Math.min(data),
                            })
                            data2.push(data)
                            this.setState({
                                chartsData: { series: data2, labels: labels },
                            })

                            this.setState({ error_text: '' })
                        }
                    })
                    .then(() => {
                        this.setState({ data: { series: data } })
                        this.setState({ loading: false })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }

            this.setState({
                isLoading: true,
            })
        }
    }

    handleChange = (value) => {
        this.setState({ value })
        for (
            let index = 0, len = this.state.currency_value.length;
            index < len;
            ++index
        ) {
            if (value == this.state.currency_value[index].value) {
                var currency = this.state.currency_value[index].label.split(':')
                this.setState({
                    currency_to_id: currency[1],
                    currency_from_id: currency[0],
                }, () => this.fetchNewRate());
            }
        }
    }

    toggle_inverse = name => (event, checked) => {
        this.setState({ [name]: checked }, () => this.fetchNewRate())
    }

    handle_click_date_range = range => {
        if (this.state.duration == range) return
        this.setState({
            isLoading: false,
        })

        this.setState({
            duration: range,
        })
    }

    render() {
        const { classes } = this.props
        const { selectedCurrency } = this.state
        const { value } = this.state
        // console.log(this.state)

        let chart_title = this.state.inverse
            ? `${this.state.currency_to_id} : ${this.state.currency_from_id}`
            : `${this.state.currency_from_id} : ${this.state.currency_to_id}`
        let chart_caption = ''

        if (!this.state.inverse) {
            switch (this.state.currency_from_id) {
                case 'AUD':
                    chart_caption += 'Australian Dollar / '
                    break
                case 'EUR':
                    chart_caption += 'Euro / '
                    break
                case 'GBP':
                    chart_caption += 'British Pound / '
                    break
                case 'USD':
                    chart_caption += 'United States Dollar / '
                    break
            }
            switch (this.state.currency_to_id) {
                case 'AUD':
                    chart_caption += 'Australian Dollar'
                    break
                case 'CAD':
                    chart_caption += 'Canadian Dollar'
                    break
                case 'EUR':
                    chart_caption += 'Euro'
                    break
                case 'HKD':
                    chart_caption += 'Hong Kong Dollar'
                    break
                case 'JPY':
                    chart_caption += 'Japanese Yen'
                    break
                case 'GBP':
                    chart_caption += 'British Pound'
                    break
                case 'SGD':
                    chart_caption += 'Singapore Dollar'
                    break
                case 'NZD':
                    chart_caption += 'New Zealand Dollar'
                    break
                case 'USD':
                    chart_caption += 'United States Dollar'
                    break
            }
        } else {
            switch (this.state.currency_to_id) {
                case 'AUD':
                    chart_caption += 'Australian Dollar / '
                    break
                case 'CAD':
                    chart_caption += 'Canadian Dollar / '
                    break
                case 'EUR':
                    chart_caption += 'Euro / '
                    break
                case 'HKD':
                    chart_caption += 'Hong Kong Dollar / '
                    break
                case 'JPY':
                    chart_caption += 'Japanese Yen / '
                    break
                case 'GBP':
                    chart_caption += 'British Pound / '
                    break
                case 'NZD':
                    chart_caption += 'New Zealand Dollar / '
                    break
                case 'SGD':
                    chart_caption += 'Singapore Dollar / '
                    break
                case 'USD':
                    chart_caption += 'United States Dollar / '
                    break
            }
            switch (this.state.currency_from_id) {
                case 'AUD':
                    chart_caption += 'Australian Dollar'
                    break
                case 'CAD':
                    chart_caption += 'Canadian Dollar'
                    break
                case 'EUR':
                    chart_caption += 'Euro'
                    break
                case 'HKD':
                    chart_caption += 'Hong Kong Dollar'
                    break
                case 'JPY':
                    chart_caption += 'Japanese Yen'
                    break
                case 'GBP':
                    chart_caption += 'British Pound'
                    break
                case 'SGD':
                    chart_caption += 'Singapore Dollar'
                    break
                case 'NZD':
                    chart_caption += 'New Zealand Dollar'
                    break
                case 'USD':
                    chart_caption += 'United States Dollar'
                    break
            }
        }

        const duration_list_select_options = [
            { value: '1d', label: 'Current Day' },
            { value: '1w', label: '1 Week' },
            { value: '1m', label: '1 Month' },
            { value: '6m', label: '6 Month' },
            { value: '1y', label: '1 Year' },
            { value: '5y', label: '5 Years' },
        ]

        return (
            <React.Fragment>
                <div>
                    {!this.props.formCurrency && <AntTabs onChange={this.handleChange}>
                        {
                            this.state.currency_value.map(item => {
                                return <>
                                    <AntTabs.TabPane tab={<>
                                        <Button type={'primary'} style={{ backgroundColor: '#00acc1' }} size={'large'}>
                                    <span style={{ fontSize: '16px', marginRight: '3px' }}>
                                        <span
                                            style={{ marginBottom: '-2px'}} className={`currency-flag currency-flag-${item.label.split(':')[0].toLowerCase()}`}
                                        /> &nbsp;{item.label.split(':')[0]}&nbsp;&nbsp;
                                    </span>
                                            <span style={{ fontSize: '16px' }}>
                                        <span style={{ marginBottom: '-2px'}} className={`currency-flag currency-flag-${item.label.split(':')[1].toLowerCase()}`} />
                                                &nbsp;{item.label.split(':')[1]}
                                    </span>
                                        </Button>
                                    </>} key={item.value}>
                                    </AntTabs.TabPane>
                                </>
                            })
                        }
                    </AntTabs> }
                    {this.state.chartsData &&
                        this.state.isLoading && (
                            <Card>
                                <React.Fragment>
                                    <div style={{textAlign: 'center'}}>
                                        <h3
                                            style={{
                                                fontWeight: 600,
                                                color: this.state.inverse
                                                    ? '#3f51b5'
                                                    : 'black',
                                            }}
                                        >
                                            {chart_title}
                                        </h3>
                                        <div>
                                            <p
                                                style={{
                                                    fontWeight: 500,
                                                    color: this.state.inverse
                                                        ? '#3f51b5'
                                                        : 'black',
                                                }}
                                            >
                                                {chart_caption}
                                            </p>
                                        </div>
                                    </div>
                                    <ChartistGraph
                                        style={{margin: 20, fontWeight: 600}}
                                        data={this.state.chartsData}
                                        type="Line"
                                        options={{
                                            lineSmooth: Chartist.Interpolation.cardinal(
                                                {
                                                    tension: 0,
                                                }
                                            ),
                                            axisY: {
                                                labelInterpolationFnc: function (
                                                    value
                                                ) {
                                                    return Number.parseFloat(
                                                        value
                                                    ).toFixed(4)
                                                },
                                            },
                                            // axisX: {
                                            //   labelInterpolationFnc: function(value) {
                                            //     if (value) {
                                            //       return moment(value, "YYYY-MM-DD").format("MMM YYYY")
                                            //     } else {
                                            //       return ""
                                            //     }
                                            //   },
                                            // },
                                            showPoint: false,
                                            low: this.state.chartMinValue,
                                            high: this.state.chartMaxValue,
                                            height: '400px',
                                            chartPadding: {
                                                top: 0,
                                                right: 20,
                                                bottom: 0,
                                                left: 20,
                                            },
                                        }}
                                        listener={dailySalesChart.animation}
                                    />
                                </React.Fragment>
                                <p>
                                    <em>
                                        The graphs above are calculated using the Market (Interbank) rate 
                                        and are not indicative of Customer Rates. 
                                    </em>
                                </p>
                            </Card>
                        )}

                    <div className={classes.row}>
                        <Chip
                            avatar={<RestoreIcon />}
                            label="Current Day"
                            onClick={() => this.handle_click_date_range('1d')}
                            className={
                                this.state.duration === '1d'
                                    ? classes.chipActive
                                    : classes.chip
                            }
                        />
                        <Chip
                            avatar={<RestoreIcon />}
                            label="1 Week"
                            onClick={() => this.handle_click_date_range('1w')}
                            className={
                                this.state.duration === '1w'
                                    ? classes.chipActive
                                    : classes.chip
                            }
                        />
                        <Chip
                            avatar={<RestoreIcon />}
                            label="1 Month"
                            onClick={() => this.handle_click_date_range('1m')}
                            className={
                                this.state.duration === '1m'
                                    ? classes.chipActive
                                    : classes.chip
                            }
                        />
                        <Chip
                            avatar={<RestoreIcon />}
                            label="6 Months"
                            onClick={() => this.handle_click_date_range('6m')}
                            className={
                                this.state.duration === '6m'
                                    ? classes.chipActive
                                    : classes.chip
                            }
                        />
                        <Chip
                            avatar={<RestoreIcon />}
                            label="1 Year"
                            onClick={() => this.handle_click_date_range('1y')}
                            className={
                                this.state.duration === '1y'
                                    ? classes.chipActive
                                    : classes.chip
                            }
                        />
                        <Chip
                            avatar={<RestoreIcon />}
                            label="5 Years"
                            onClick={() => this.handle_click_date_range('5y')}
                            className={
                                this.state.duration === '5y'
                                    ? classes.chipActive
                                    : classes.chip
                            }
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.inverse}
                                    color={'primary'}
                                    onChange={this.toggle_inverse('inverse')}
                                    aria-label="inverse"
                                />
                            }
                            label="Inverse"
                        />
                    </div>
                </div>

                {this.state.error_text != '' && (
                    <div style={{ color: 'red' }}>{this.state.error_text} </div>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app_state: state.app_state,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Charts))
