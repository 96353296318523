import {Button, Card, Modal, Table, message} from "antd";
import React, {useEffect, useState} from 'react';
import {DataStatus} from "../../ComponentInfo/Status";
import {API} from "aws-amplify";
import ListTable from "../../ListTable/ListTable";
import {EyeOutlined, ReloadOutlined} from "@ant-design/icons";
import FileUploader from "../../FileUploader/FileUploader";
import dayjs from "dayjs";

const TransferDocuments = ({ getColumnSearchProps, transfer_id, state }) => {

    const [componentState, setComponentState] = useState({
        data: []
    })

    const [componentInfo, setComponentInfo] = useState({
        status: DataStatus.Loaded,
        callback: () => fetchTransferDocuments()
    });

    const fetchTransferDocuments = () => {
        setComponentInfo(prev => ({...prev, status: DataStatus.Loading}));
        API.post("commons", "/fetch", {
            body: {
                context: 'transferDocuments',
                fields: ['*'],
                condition: {transferId: transfer_id, deleted: false, visiblePublic: true}
            }
        }).then(res => {
            setComponentInfo(prev => ({...prev, status: DataStatus.Loaded}));
            setComponentState(prev => ({...prev, data: res}));
        }).catch(() => {
            setComponentInfo(prev => ({...prev, status: DataStatus.ErrorState}));
        });
    }
    const buildColumns = () => {
        return [
            {
                title: 'Document No.',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: 'descend'
            },
            {
                title: 'Created Date',
                dataIndex: 'recordCreated',
                ...getColumnSearchProps({
                    dataIndex: 'recordCreated',
                    filterInputType: 'DATE',
                    render: (text, record) =>  text != null ? dayjs(text).format('DD/MM/YYYY') : '-'
                })
            },
            {
                title: 'Description',
                dataIndex: 'description',
            },
            {
                title: '',
                dataIndex: 'documentId',
                ...getColumnSearchProps({
                    dataIndex: 'documentId',
                    render: (text, record) => {
                        return <div style={{ display: 'flex', justifyContent: 'start'}}>
                            <Button type={'primary'} icon={<EyeOutlined />} onClick={() => {
                                const loading = message.loading("Loading documents. Please wait..", 0);
                                let documents = [record.documentId];
                                const attachments = JSON.parse(record.attachments);
                                if (attachments && attachments.ids && attachments.ids.length > 0) {
                                    documents = [...documents, ...attachments.ids];
                                }
                                API.post("commons", "/fetch", {
                                    body: {
                                        context: 'documents',
                                        fields: ['*'],
                                        condition: {id: documents}
                                    }
                                }).then((response) => {
                                    Modal.confirm({
                                        width: '720px',
                                        title: 'Documents & Attachments',
                                        icon: false,
                                        content: <>
                                        {response.map(i => {
                                            return <FileUploader uploaderType={'PDF'} thumbType={'picture-card'} fetchFileList={() => {
                                                const files = [];
                                                if (i != null && i.file_ref != null) {
                                                    const file = {
                                                        filename: i.file_ref,
                                                        name: i.file_ref,
                                                        uid: i.file_ref,
                                                        status: "done"
                                                    }
                                                    files.push(file);
                                                }
                                                return Promise.resolve(files);
                                            }}></FileUploader>
                                        })}
                                        </>
                                    })
                                }).catch(err => {
                                    console.log(err);
                                }).finally(() => loading());
                            }}></Button>
                        </div>
                    }
                })
            }

        ]
    }

    useEffect(() => {
        fetchTransferDocuments();
    }, [state.current_client]);

    return <Card title={<>
        <Button type={'primary'} icon={<ReloadOutlined />} danger={true} style={{ float: 'right' }} onClick={() => fetchTransferDocuments()}>Reload Page</Button>
    </>}>
        <Table size={'small'}
               columns={buildColumns()}
               dataSource={componentState.data}
               loading={componentInfo.status === DataStatus.Loading}></Table>
    </Card>
}

export default ListTable(TransferDocuments);