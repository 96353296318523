import React from "react";
import GridContainer from './components/Grid/GridContainer'
import GridItem from './components/Grid/GridItem'
import passportImage from './assets/img/identifications/sample_passport.jpg'
import driversLicenceImage from './assets/img/identifications/sample_drivers_vic.jpg'
import medicareImage from './assets/img/identifications/sample_medicare.jpg'
import visaImage from './assets/img/identifications/sample_visa.jpg'
import birthRecordImage from './assets/img/identifications/sample_birthcertificate.jpg'
import marriageRecordImage from './assets/img/identifications/sample_marriagecertificate.jpg'

export const AppTemplates = {
    countryTemplate: (item) => {
        return <React.Fragment>
                        <span>
                            <img width={15} height={15}
                                src={
                                    item.iso_alpha_2 !== ''
                                        ? (require(`./assets/img/flags/${item.iso_alpha_2 ? item.iso_alpha_2 : ''}.png`))
                                        : ''
                                }
                            />
                        </span>{' '}
            &nbsp; {item.full_name}{' '}
        </React.Fragment>
    },
    documentHelpTemplate: (categoryId) => {
        switch (categoryId) {
            case 1:
                return (
                    <React.Fragment>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <h6>When providing details of a Passport</h6>
                                <img src={passportImage} alt="Passport" style={{ width: '100%' }} />
                                <p>When using a passport for identification, we require</p>
                                <ul>
                                    <li>Document number</li>
                                    <li>Expiry date</li>
                                    <li>Country of issue</li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
            case 2:
                return (
                    <React.Fragment>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <h6>When providing details of a Drivers License</h6>
                                <img src={driversLicenceImage} alt="Drivers licence" style={{ width: '100%' }} />
                                <p>When using a drivers licence for identification, we require</p>
                                <ul>
                                    <li>Document number</li>
                                    <li>Expiry date</li>
                                    <li>State or territory of issue</li>
                                    <li>Country of issue</li>
                                    <li>
                                        Please note we require two uploads (the front and back
                                        of the licence)
                                    </li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
            case 3:
                return (
                    <React.Fragment>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <h6>When providing details of a Medicare Card</h6>
                                <img src={medicareImage} alt="Medicare card" style={{ width: '100%' }} />
                                <p>When using a Medicare card for identification, we require</p>
                                <ul>
                                    <li>Document number</li>
                                    <li>Expiry date (month and year only)</li>
                                    <li>Country of issue</li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
            case 4:
                return (
                    <React.Fragment>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <h6>When providing details of a Visa</h6>
                                <img src={visaImage} alt="Visa" style={{ width: '100%' }} />
                                <p>When using a visa for identification, we require</p>
                                <ul>
                                    <li>Document number (visa number)</li>
                                    <li>Expiry date (stay until date)</li>
                                    <li>Country of issue</li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
            case 5:
                return (
                    <React.Fragment>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <h6>When providing Bank Statement details</h6>
                                <p>When using a bank statement for proof of address, we require</p>
                                <ul>
                                    <li>The top of the statement</li>
                                    <li>Including full bank details</li>
                                    <li>We do not need to see your balance</li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
            case 7:
                return (
                    <React.Fragment>
                        <GridContainer>
                            <GridItem xs={12} sm={12}>
                                <h6>When providing details of a Birth or Marriage Record</h6>
                                <img src={marriageRecordImage} alt="Marriage record" style={{ width: '100%' }} />
                                <p>When using a birth or marriage record for identification, we require</p>
                                <ul>
                                    <li>Document number</li>
                                    <li>Country of issue</li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
                break
            default:
                return <React.Fragment />
        }
    },
    openBase64File: (base64Url, fileType = 'application/pdf') => {
        base64Url = base64Url.split(',')[1];
        const byteCharacters = atob(base64Url);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
    }
}