import React, {FC} from "react";
import {Skeleton, Spin, Tag, Typography} from "antd";
import {LoadingOutlined, ReloadOutlined, SyncOutlined} from "@ant-design/icons";


export const DataStatus = {
    Loading: 'Loading',
    Loaded: 'Loaded',
    ErrorState: 'ErrorState'
}

const Status = ({
                                          children,
                                          componentInfos = [],
                                          showLoadingText = false,
                                          isShowRetry = false,
                                          showContent = false,
                                          showNotchLoader = false,
                                      }) => {

    const isLoading = componentInfos.some(info => info.status === DataStatus.Loading);
    const hasError = componentInfos.some(info => info.status === DataStatus.ErrorState);
    const isLoaded = componentInfos.every(info => info.status === DataStatus.Loaded);

    const reFetchAll = () => {
        componentInfos.forEach(item => {
            if (item.callback != null) {
                item.callback(item.callbackArgs || null);
            }
        });
    }

    return <>
        {
            showNotchLoader && isLoading && <div className={'notch-loader'}><Tag icon={<SyncOutlined size={20} spin={true}/>}
                                                                                 color="processing">processing</Tag></div>
        }
        {
            isLoading
            && <Skeleton active />
            // <div className="imgloader"
            //      style={{position: 'absolute', top: '50%', left: '50%', zIndex: 9999, textAlign: 'center'}}>
            //     <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
            //     {showLoadingText && <Typography.Text
            //         style={{marginTop: '10px', fontWeight: "bolder", display: 'block'}}>Loading...</Typography.Text>}
            // </div>
        }
        {
            hasError && isShowRetry &&
            <Typography.Text type={'danger'}
                             strong
                             className="has-error"
                             style={{textAlign: 'center', cursor: 'pointer'}}
                             onClick={() => reFetchAll()}>
                Retry <ReloadOutlined/>
            </Typography.Text>
        }
        {
            isLoaded && children
        }
        {
            hasError && showContent && children
        }
        {
            !isShowRetry && showContent
            && isLoading
            && <span style={{ background: isLoading ? '#e0e0e0' : ''}}>{children}</span>
        }
        {
            !isShowRetry
            && !showContent
            && hasError
            && <Typography.Text type={'danger'} strong>Error !! Please contact customer service..</Typography.Text>
        }
    </>
}

export default Status;