import React, {useEffect, useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import {Button, Card, Col, Form, Input, message, Modal, Row, Select, Spin, Switch, Table, Tag, Typography} from "antd";
import moment from "moment";
import {BulbOutlined, DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import ListTable from "../../../components/ListTable/ListTable";
import axios from 'axios';
import {RateAlertAPI} from "./RateAlertsUtil";
import {API} from "aws-amplify";
import ChartistGraph from "react-chartist";
import FxCharts from "../../../components/EconomicInformation/FxCharts";

const { Text, Link } = Typography;

const styles = {
    currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem',
            width: 52,
            height: 26
        }
    },
    currencyLabel: {
        textAlign: 'right',
        '& > p': {
            marginBottom: -5,
            fontSize: '0.6rem'
        }
    },
    marginRight1: {
        marginRight: '1em',
    },
    positionRelative: {
        position: 'relative'
    }
};

const CurrencyElement = ({
                             classes, currency
                         }) => (
    <div className={classes.currency}>
        <div className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`}/>
    </div>
);
const RateAlerts = ({classes, app_state, getColumnSearchProps: columnSearchProps}) => {

    const [form] = Form.useForm();
    const [formCurrency, setFormCurrency] = useState({
        from: null,
        to: null
    });
    const [state, setState] = useState({
        loading: false,
        alerts: [],
        curFrom: [],
        curTo: [],
        modalOpen: false,
        modalDataFetching: false,
        clientList: [],
        currenciesList: [],
        activeAlertId: null,
        currentRate: null,
        loadChart: false
    });

    const getCurrentRate = (fromCur, toCur, rates) => {
        const sourceCur = rates.find(item => item.data.source === fromCur);
        if (sourceCur != null) {
            let rate = sourceCur.data.quotes[`${fromCur}${toCur}`];
            if (app_state.current_client != null) {
                rate = !app_state.current_client.rates_marketCharts ? rate - (rate * app_state.current_client.default_rate) : rate;
            }
            return `${Number(rate).toFixed(4)}` || '-';
        }
        return '-';
    }

    const fetchRateAlerts = async () => {
        setState(prev => ({...prev, loading: true}));
        const res = await RateAlertAPI.fetchRateAlerts(app_state.current_client.id);
            let resp = res.map(item => ({
                ...item,
                arrow: '⮕',
                record_created: item.record_created != null ? moment(item.record_created).format('DD/MM/YYYY') : null,
                action: item.id,
            }));
            const rates = await calculateCurrentRates(resp);
                resp = resp.map(item => ({...item, currentRate: getCurrentRate(item.from_iso_alpha_3, item.to_iso_alpha_3, rates )}))
                const currenciesFrom = resp
                    .map(item => item.from_iso_alpha_3)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .map(item => ({text: item, value: item}));
                const currenciesTo = resp
                    .map(item => item.to_iso_alpha_3)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .map(item => ({text: item, value: item}));
                setState(prev => ({...prev, alerts: resp, loading: false, curTo: currenciesTo, curFrom: currenciesFrom}));
    }

    const calculateCurrentRates = async (resp) => {
        const fromCurrencies = resp.map(i => i.from_iso_alpha_3).filter((value, index, self) => self.indexOf(value) === index);
        const toCurrencies = resp.map(i => i.to_iso_alpha_3).filter((value, index, self) => self.indexOf(value) === index);
        const currenciesSources = fromCurrencies.map(item => {
            const currencies = toCurrencies.filter(i => item !== i).join(',');
            let accessKey = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
            if (currencies.length > 0) {
                accessKey += `&currencies=${currencies}&source=${item}`
            }
            return axios.get(accessKey);
        });
        return Promise.all(currenciesSources);
    }



    const buildColumns = () => {
        return [
            {
                dataIndex: 'id',
                title: 'ID',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.id - b.id,
            },
            {
                dataIndex: 'record_created',
                title: 'Created',
                ...columnSearchProps({
                    dataIndex: 'record_created',
                    filterInputType: 'DATE',
                    render: (text, record) => {
                        return text
                    }
                })
            },
            {
                dataIndex: 'from_iso_alpha_3',
                title: 'Currency From',
                filters: state.curFrom,
                onFilter: (value, record) => record.from_iso_alpha_3 === value,
                ...columnSearchProps({
                    dataIndex: 'from_iso_alpha_3',
                    filterInputType: 'SELECT',
                    render: (text, record) => {
                        return <div style={{ display: 'flex', justifyContent: 'start'}}><CurrencyElement currency={text} classes={classes}/> {record.from_iso_alpha_3}</div>
                    }
                })
            },
            {
                dataIndex: 'arrow',
                title: '',
                render: () => '⮕'
            },
            {
                dataIndex: 'to_iso_alpha_3',
                title: 'Currency To',
                onFilter: (value, record) => record.to_iso_alpha_3 === value,
                filters: state.curTo,
                ...columnSearchProps({
                    dataIndex: 'to_iso_alpha_3',
                    filterInputType: 'SELECT',
                    render: (text, record) => {
                        return <div style={{ display: 'flex', justifyContent: 'start'}}><CurrencyElement currency={text}
                                                                                                         classes={classes}/> {record.to_iso_alpha_3}
                        </div>
                    }
                })
            },
            {
                dataIndex: 'rate',
                title: 'Rate',
                render: (text) => text != null ? text.toFixed(4) : '-'
            },
            {
                dataIndex: 'currentRate',
                title: 'Current Rate',
                render: (text) => text
            },
            // {
            //     dataIndex: 'notify_changes',
            //     title: 'Status',
            //     filters: [{text: 'Active', value: 1}, {text: 'Triggered', value: 0}],
            //     onFilter: (value, record) => record.notify_changes === value,
            //     ...columnSearchProps({
            //         dataIndex: 'notify_changes',
            //         filterInputType: 'SELECT',
            //         render: (text, record) => {
            //             return <Tag color={text === 1 ? 'success' : 'red'}>{text === 1 ? 'Active' : 'Triggered'}</Tag>
            //         }
            //     })
            // },
            {
                dataIndex: 'action',
                title: '',
                render: (id) => {
                    return <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {/*<Button type={'link'} icon={<EditOutlined/>} onClick={() => onEditAlert(id)}></Button>*/}
                        <Button type={'link'} danger={true} icon={<DeleteOutlined/>} onClick={() => {
                            Modal.confirm({
                                title: 'Confirm',
                                content: `Are you sure you want to delete alert with ID #${id} ?`,
                                onOk: () => onRemoveAlert(id),
                                footer: (_, {OkBtn, CancelBtn}) => (
                                    <>
                                        <CancelBtn/>
                                        <OkBtn/>
                                    </>
                                ),
                            });
                        }}></Button>
                    </div>
                }
            },

        ]
    }

    const onEditAlert = (id) => {
        setState(prev => ({...prev, modalOpen: true, modalDataFetching: true, activeAlertId: id}));
        RateAlertAPI.fetchRateAlert(id).then(response => {
            form.setFieldsValue({
                clientID: response.clientID,
                currencyFromID: response.currencyFromID,
                currencyToID: response.currencyToID,
                rate: response.rate,
                notify_changes: response.notify_changes === 1
            });
            setState(prev => ({...prev, modalDataFetching: false}));
        }).catch(err => {
            setState(prev => ({...prev, modalOpen: false, modalDataFetching: false, activeAlertId: null}));
            message.error("Failed to fetch the selected alert");
            console.log(err);
        })
    }

    const onRemoveAlert = (id) => {
        RateAlertAPI.updateAlert(id, {deleted: true})
            .then(() => fetchRateAlerts())
            .catch(err => {
                console.log(err);
                message.error("Failed to delete alert. Try again...");
            })
    }

    const fetchInitials = async () => {
        const currenciesList = await API.get('currencies', `/currencies/get-list-priority`, {});
        setState(prev => ({
            ...prev,
            currenciesList: currenciesList.map(item => ({
                alt:  item.iso_alpha_3 + ': ' + item.full_name,
                label: <>
                    <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
                    &nbsp;
                    {item.iso_alpha_3 + ': ' + item.full_name}
                </>,
                value: item.id,
                shortName: item.iso_alpha_3
            }))
        }))
        form.setFieldsValue({
            currencyFromID: 1,
            currencyToID: 5,
        });
    }

    const handleSubmit = (payload) => {
        setState(prev => ({...prev, modalDataFetching: true}));
        if (state.currentRate != null) {
            if (state.currentRate >= Number(payload.rate)) {
                payload = {...payload, goingDownTrigger: true, goingUpTrigger: false};
            } else {
                payload = {...payload, goingDownTrigger: false, goingUpTrigger: true};
            }
        }
        payload = {...payload, notify_changes: true, recordCreated: dayjs().format('YYYY-MM-DD'), clientID: app_state.current_client.id, rate: Number(payload.rate).toFixed(4)};
        const action = state.activeAlertId != null ? RateAlertAPI.updateAlert(state.activeAlertId, payload) : RateAlertAPI.saveAlert(payload);
        action.then(() => {
            setState(prev => ({
                ...prev,
                modalOpen: false,
                modalDataFetching: false,
                activeAlertId: null
            }));
            form.resetFields();
            fetchRateAlerts();
        }).catch(err => {
            message.error('Failed to save alert.. Please try again');
            setState(prev => ({
                ...prev,
                modalDataFetching: false
            }))
        })
    }

    const fetchCurrentRate = () => {
        const fromCurrency = form.getFieldValue('currencyFromID');
        const toCurrency = form.getFieldValue('currencyToID');
        if (fromCurrency != null && toCurrency != null && state.currenciesList.length > 0) {
            const fCur = state.currenciesList.find(i => i.value === fromCurrency).shortName;
            const tCur = state.currenciesList.find(i => i.value === toCurrency).shortName;
            let accessKey = 'https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f';
            accessKey += `&currencies=${tCur}&source=${fCur}`
            axios.get(accessKey).then(res => {
                let rate = res.data.quotes[`${fCur}${tCur}`];
                if (app_state.current_client != null) {
                    rate = !app_state.current_client.rates_marketAlerts ? rate - (rate * app_state.current_client.default_rate) : rate;
                }
                setState(prev => ({...prev, currentRate: rate }));
            });
            setFormCurrency({from: fCur, to: tCur});
        }
    }

    useEffect(() => {
        if (app_state && app_state.current_client && app_state.current_client.id != null) {
            fetchInitials();
            fetchRateAlerts();
            fetchCurrentRate();
        }
    }, [app_state.current_client]);

    useEffect(() => {
        fetchCurrentRate();
    }, [state.currenciesList]);
    return <div className={classes.positionRelative}>
        <div style={{ backgroundColor: '#fff', padding: '10px', marginTop: '10px', marginBottom: '10px' }}>
            <Form layout='vertical' name={'rate-alert'} form={form} onFinish={handleSubmit}>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item name="currencyFromID" label={'Currency From'}
                                   rules={[{required: true, message: 'Field is required'}]}>
                            <Select onChange={() => fetchCurrentRate()} optionFilterProp={'alt'}
                                    placeholder={'-- SELECT CURRENCY FROM --'} showSearch={true} allowClear={true}
                                    options={state.currenciesList}></Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="currencyToID" showSearch={true} label={'Currency To'}
                                   rules={[{required: true, message: 'Field is required'}]}>
                            <Select onChange={() => fetchCurrentRate()} showSearch={true} optionFilterProp={'alt'}
                                    allowClear={true} placeholder={'-- SELECT CURRENCY TO --'}
                                    options={state.currenciesList}></Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="rate" label={'Rate'} rules={[{required: true, message: 'Field is required'}]}>
                            <Input placeholder={'Rate..'}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={` `}>
                            <Button htmlType={'submit'} type={'primary'} loading={state.modalDataFetching}>Add Alert</Button>
                        </Form.Item>
                    </Col>
                </Row>
                {state.currentRate != null
                    && <Text type={'danger'}>Current Rate: {state.currentRate.toFixed(4)}</Text>}
            </Form>
        </div>
        <FxCharts formCurrency={formCurrency} />
        <Spin tip="Loading..." style={{ position: 'absolute', top: '50%', left: '50%' }} spinning={state.modalDataFetching}/>
        <h4 style={{ marginTop: '15px' }}><BulbOutlined /> Active Rate Alerts</h4>
        <Card>
            <Table dataSource={state.alerts}
                   columns={buildColumns()}
                   loading={state.loading}>
            </Table>
        </Card>
    </div>
}


const mapStateToProps = state => {
    return {
        app_state: state.app_state
    }
}
export default connect(mapStateToProps)(withStyles(styles)(ListTable(RateAlerts)));