import React from 'react';
import {Card, Col, Form, Input, message, Radio, Row, Space, Typography} from "antd";
import withStyles from "@material-ui/core/styles/withStyles";
import {RegistrationContext} from "../../../libs/RegistrationProvider";
import {connect} from "react-redux";


//var moment = require('moment');
const styles = {
    expandedBackground: {
        backgroundColor: 'rgb(240, 242, 245)',
        padding: '15px',
        borderRadius: '5px'
    },
}

class Step0AccountHolder extends React.Component {

    accountForm = React.createRef();
    static contextType = RegistrationContext;

    constructor(props) {
        super(props);
        this.state = {
            formValues: {
                account_type: 1,
                account_business_type: 1
            }
        }
    }

    componentDidMount() {
        let accType = 1
        if (this.props.app_state && this.props.app_state.current_client && this.props.app_state.current_client.account_type) {
            accType = this.props.app_state.current_client.account_type
                ? this.props.app_state.current_client.account_type.toString()
                : "1";
        }
        let account_business_type = null;
        if (this.props.app_state && this.props.app_state.current_client && this.props.app_state.current_client.account_business_type) {
        account_business_type = this.props.app_state.current_client.account_business_type
            ? this.props.app_state.current_client.account_business_type.toString()
            : "1";
        }
        const formValues = {
            account_type: accType,
            account_business_type: account_business_type,
            trading_name: this.props.app_state.current_client.business_trading_name,
            company_name: this.props.app_state.current_client.business_company_name,
            company_number: this.props.app_state.current_client.business_company_number,
            abn: this.props.app_state.current_client.business_abn,
            trust_name: this.props.app_state.current_client.trust_name,
            first_name: this.props.app_state.current_client.first_name,
            last_name: this.props.app_state.current_client.last_name,
        };
        this.context.setRegistration(prev => ({
            ...prev,
            account_type: this.props.app_state.current_client.account_type,
            account_business_type: this.props.app_state.current_client.account_business_type,
        }))
        this.setState(prev => ({...prev, formValues: formValues}));
        this.accountForm.current.setFieldsValue(formValues);
    }

    getFormFields = (key) => {
        return this.state.formValues
        && this.state.formValues[key]
            ? this.state.formValues[key] : null
    }

    isValidated = async () => {
        this.context.setRegistration(prev => ({...prev, account: this.accountForm.current.getFieldsValue()}))
    }

    async preValidate() {
        const isValidated = await this.accountForm.current.validateFields().then(() => true).catch(() => false);
        if (!isValidated) {
            message.warning("Some of the fields requires your attention");
        }
        return isValidated;
    }

    sendState() {
        return this.state;
    }


    render() {
        return (
            <>
                <div className={this.props.classes.expandedBackground}>
                    <Form initialValues={{account_type: "1", account_business_type: "1"}} ref={this.accountForm}
                          disabled={this.context.registration.reg_disabled}
                          onValuesChange={(changed, all) => {
                              this.setState(prev => ({...prev, formValues: all}));
                          }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Card size={'small'} title={'Select Account Type'}>
                                    <Row>
                                        <Col flex={'auto'}>
                                            <Form.Item name="account_type" labelCol={{span: 24}}
                                                       wrapperCol={{span: 24}}>
                                                <Radio.Group>
                                                    <Space direction={'vertical'}>
                                                        <Radio value="1" onChange={(e) => {
                                                            this.context.setRegistration(prev => ({
                                                                ...prev,
                                                                account_type: Number(e.target.value)
                                                            }))
                                                        }}>Individual</Radio>
                                                        <Radio value="2" onChange={(e) => {
                                                            this.accountForm.current.setFieldValue("account_business_type", "1");
                                                            this.context.setRegistration(prev => ({
                                                                ...prev,
                                                                account_type: Number(e.target.value)
                                                            }))
                                                        }}>Business / Company</Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col flex={'auto'}>
                                            {
                                                <Form.Item name="account_business_type"
                                                           style={{display: this.getFormFields('account_type') === (2).toString() ? 'block' : 'none'}}
                                                           labelCol={{span: 24}}
                                                           wrapperCol={{span: 24}}>
                                                    <Radio.Group style={{display: 'flex', flexDirection: 'column'}}>
                                                        <Radio value="1" onChange={(e) => {
                                                            this.context.setRegistration(prev => ({
                                                                ...prev,
                                                                account_business_type: Number(e.target.value)
                                                            }))
                                                        }}>Sole Trader</Radio>
                                                        <Radio value="2" onChange={(e) => {
                                                            this.context.setRegistration(prev => ({
                                                                ...prev,
                                                                account_business_type: Number(e.target.value)
                                                            }))
                                                        }}>Company</Radio>
                                                        <Radio value="3" onChange={(e) => {
                                                            this.context.setRegistration(prev => ({
                                                                ...prev,
                                                                account_business_type: Number(e.target.value)
                                                            }))
                                                        }}>Trust</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            }
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Card title={'Account Holder'} size={'small'}>
                                    {
                                        this.getFormFields('account_type') === (2).toString() ? <>
                                            <Typography.Paragraph>
                                                Provide company name and company number / ACN or ABN if Australian
                                                company,
                                                or company number if international
                                            </Typography.Paragraph>
                                            {
                                                this.getFormFields('account_business_type') === (1).toString() &&
                                                <Form.Item name="trading_name"
                                                           rules={[{
                                                               required: true,
                                                               message: 'Trading name is required'
                                                           }]}
                                                           label={'Trading Name (or N/A if none)'}>
                                                    <Input/>
                                                </Form.Item>
                                            }
                                            {
                                                this.getFormFields('account_business_type') === (2).toString() &&
                                                <>
                                                    <Form.Item name="company_name" label={'Company Name'}
                                                               rules={[{
                                                                   required: true,
                                                                   message: 'Company name is required'
                                                               }]}>
                                                        <Input/>
                                                    </Form.Item>
                                                    <Form.Item name="company_number" label={'Company Number (ACN)'}
                                                               rules={[{
                                                                   required: true,
                                                                   message: 'Company name is required'
                                                               }]}>
                                                        <Input/>
                                                    </Form.Item>
                                                </>
                                            }
                                            {
                                                this.getFormFields('account_business_type') === (3).toString() &&
                                                <Form.Item name="trust_name" label={'Trust Name'} rules={[{
                                                    required: true,
                                                    message: 'Trust name is required'
                                                }]}>
                                                    <Input/>
                                                </Form.Item>
                                            }
                                            <Form.Item name="abn" label={'ABN'}
                                                       rules={[{required: false, message: 'ABN is required'}]}>
                                                <Input/>
                                            </Form.Item>
                                        </> : <>
                                            <Typography.Paragraph>Confirm your true and correct legal
                                                name</Typography.Paragraph>
                                            <Form.Item name="first_name" label={'First Name'}
                                                       rules={[{required: true, message: 'First name is required'}]}>
                                                <Input/>
                                            </Form.Item>
                                            <Form.Item name="last_name" label={'Last Name'}
                                                       rules={[{required: true, message: 'Last name is required'}]}>
                                                <Input/>
                                            </Form.Item>
                                        </>}

                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};


const Step0AccountHolderContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Step0AccountHolder));

export default Step0AccountHolderContainer;