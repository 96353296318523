import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import {
    //fetchClientDetails,
    loadTransferDetailsRequest,
    loadTransferDetailsSuccess,
} from '../../redux/actions/transfers'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'

import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import Button from '../CustomButtons/Button'
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react'
import NavPills from 'components/NavPills/NavPills.jsx'
import Clearfix from 'components/Clearfix/Clearfix.jsx'
import { API } from 'aws-amplify'

import TransferDetailRemittance from './TransferDetail/TransferDetailRemittance'
import TransferDetailPayout from './TransferDetail/TransferDetailPayout'
import TransferDetailPayoutSingle from './TransferDetail/TransferDetailPayoutSingle'
import TransferDetailPayoutSinglePopUp from './TransferDetail/TransferDetailPayoutSinglePopUp'

import TransferDetailSummary_Payout from './TransferDetail/TransferDetailSummary_Payout'

import TransferDetailPayoutToLedger from './TransferDetail/TransferDetailPayoutToLedger'
import { injectIntl } from 'react-intl'
import CardTravel from '@material-ui/icons/CardTravel'
import Table from 'components/Table/Table.jsx'
// import TimelineComponent from "components/Timeline/Timeline.jsx";
import queryString from 'query-string'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import HorizontalStepperNew from '../Timeline/HorizontalStepperNew'
import TransferDocuments from "./TransferDetail/TransferDocuments";

var moment = require('moment')

const staffEditStyles = {
    selectStyles,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px',
    },
    registerButton: {
        float: 'right',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 22,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },

}

class TransferEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            edit_mode: false,
            loaded: false,
            is_loading: true,
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            password: '',
            security_entities: [],
            security_entities_forexsport: false,
            security_entities_forexworldwide: false,
            security_entities_forexstudent: false,
            navpill_active: 0,
            nickname: '',
            client: '',
            client_id: '',
            transaction_datetime: '',
            beneficiary_name: '',
            client_list: [],
            currencies_list: [],
            currencies_list_priority: [],
            beneficiaries_list: [],
            beneficiary_id: '',
            beneficiary: {},
            currency_from_id: '',
            currency_to_id: '',
            amount_from: '',
            amount_to: '',
            client_rate: '',
            settlement_date: '',
            transfer_status_list: [],
            remitting_bank: '',
            transfer_status: [],
            clientHoldingAccountId: null,
            ledgerFrom: null,
        }
    }

    componentDidMount() {
        this.getActive();
        if (this.props.transfer_detail_id) {
            this.props.loadTransferDetailsRequest()

            API.get('transfers', `/get/id-v2/${this.props.transfer_detail_id}`)
                .then(transfer_detail => {
                    console.log(transfer_detail)
                    this.setState({
                        loaded: true,
                        is_loading: false,
                        id: transfer_detail.fullList.id,
                        isMultiPay: transfer_detail.fullList.isMultiPay,
                        client_id: transfer_detail.fullList.client_id,
                        created_datetime:
                            transfer_detail.fullList.created_datetime,
                        memo: transfer_detail.fullList.memo,
                        nickname: transfer_detail.fullList.nickname,
                        clients_firstname:
                            transfer_detail.fullList.clients_firstname,
                        clients_lastname:
                            transfer_detail.fullList.clients_lastname,
                        clients_fullname:
                            transfer_detail.fullList.clients_firstname +
                            ' ' +
                            transfer_detail.fullList.clients_lastname,
                        client: transfer_detail.fullList.client,
                        transaction_datetime:
                            transfer_detail.fullList.transaction_datetime,
                        beneficiary_id: transfer_detail.fullList.beneficiary_id,
                        beneficiary_name: transfer_detail.fullList.beneficiary,
                        currency_from_id:
                            transfer_detail.fullList.currency_from_id,
                        currency_base:
                            transfer_detail.fullList.currency_from_iso_alpha_3,
                        currency_to_id: transfer_detail.fullList.currency_to_id,
                        currency_terms:
                            transfer_detail.fullList.currency_to_iso_alpha_3,
                        amount_from: transfer_detail.fullList.amount_from,
                        amount_to: transfer_detail.fullList.amount_to,
                        client_rate: transfer_detail.fullList.client_rate,
                        settlement_date:
                            transfer_detail.fullList.settlement_date,
                        remitting_bank: transfer_detail.fullList.remitting_bank,
                        remitting_bank_id:
                            transfer_detail.fullList.remitting_bank_account_id,
                        remittingBankAccountId: transfer_detail.fullList.remitting_bank_account_id,

                        transfer_status: transfer_detail.status,
                        status: transfer_detail.fullList.status,
                        clientHoldingAccountId:
                            transfer_detail.fullList.clientHoldingAccountId,
                        ledgerFrom: transfer_detail.fullList.ledgerFrom,
                        fee_visible_amount: transfer_detail.fullList.fee_visible_amount,
                    })
                    this.props.loadTransferDetailsSuccess()
                })
                .catch(error => {
                    console.log(error)
                })

            API.get('currencies', `/currencies/get-list-priority`)
                .then(response => {
                    this.setState({
                        currencies_list_priority: response,
                    })
                })
                .catch(error => {
                    console.log(error)
                })

            API.get('transfers', `/get/transfer-status`).then(response => {
                this.setState({
                    transfer_status_list: response,
                })
                return response
            })
        }
    }

    //Remove unused function

    // componentDidUpdate(prevProps, prevState, snapshot) {
    // }

    async saveTransferDetail(transfer_detail) {
        return API.put(
            'transfers',
            `/update/id/${this.props.transfer_detail_id}`,
            {
                body: transfer_detail,
            }
        )
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    handleToggle = name => event => {
        this.setState({ [name]: event.target.checked })
    }

    toggleEdit = () => {
        const edit_mode = !this.state.edit_mode
        this.setState({ edit_mode: edit_mode })
    }

    handleSubmit = async event => {
        event.preventDefault()
        this.setState({ isLoading: true })
        try {
            await this.saveTransferDetail({
                nickname: this.state.nickname,
                client_id: this.state.client_id,
                transaction_datetime: this.state.transaction_datetime,
                beneficiary_id: this.state.beneficiary_id,
                currency_from_id: this.state.currency_from_id,
                currency_to_id: this.state.currency_to_id,
                amount_from: this.state.amount_from,
                amount_to: this.state.amount_to,
                client_rate: this.state.client_rate,
                settlement_date: this.state.settlement_date,
            })
        } catch (error) {
            console.log(error)
        }
    }

    handleSelectChange = event => {
        this.setState({ [event.target.name]: event.target.value })

        if (event.target.name === 'client_id') {
            this.getClientDetails(event.target.value)
        }
        if (event.target.name === 'beneficiary_id') {
            this.getBeneficiaryDetails(event.target.value)
        }
        if (event.target.name === 'currency_base') {
            this.setCurrencyBaseDetails(event.target.value)
        }
        if (event.target.name === 'currency_terms') {
            this.setCurrencyTermsDetails(event.target.value)
        }
    }

    getBeneficiaryDetails(beneficiary_id) {
        API.get('beneficiaries', `/get/id/${beneficiary_id}`)
            .then(response => {
                console.log(response)
                this.setState({
                    beneficiary_id: response.id,
                    beneficiary_name: response.nickname,
                })
                return response
            })
            .then(response => {
                API.get(
                    'currencies',
                    `/currencies/get/${response.account_currency}`
                )
                    .then(response => {
                        this.setState({
                            beneficiary: Object.assign(
                                {},
                                this.state.beneficiary,
                                {
                                    account_currency_nickname: `${
                                        response.full_name
                                    } [${response.iso_alpha_3}]`,
                                }
                            ),
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }

    getClientDetails(client_id) {
        API.get('clients', `/get/id/${client_id}`)
            .then(response => {
                this.setState({
                    client: response,
                })
                return response.team_id
            })
            .then(team_id => {
                API.get('teams', `/get/div-entity/${team_id}`)
                    .then(response => {
                        this.setState({
                            client: Object.assign({}, this.state.client, {
                                division_nickname: response.division_nickname,
                                entity_nickname: response.entity_nickname,
                                team_nickname: response.team_nickname,
                            }),
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }

    setCurrencyBaseDetails(currency_id) {
        API.get('currencies', `/currencies/get/${currency_id}`)
            .then(response => {
                this.setState({
                    currency_from_id: response.id,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    setCurrencyTermsDetails(currency_id) {
        API.get('currencies', `/currencies/get/${currency_id}`)
            .then(response => {
                this.setState({
                    currency_to_id: response.id,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }


    getNavPill_Summary = () => {
        const { classes } = this.props

        let currency_from_name = ''
        let currency_to_name = ''
        let currency_from_flag = ''
        let currency_to_flag = ''
        for (var i in this.state.currencies_list_priority) {
            if (
                this.state.currencies_list_priority[i].id ===
                this.state.currency_from_id
            ) {
                currency_from_name = this.state.currencies_list_priority[i]
                    .short_name
                currency_from_flag = this.state.currencies_list_priority[i]
                    .iso_alpha_3
            }
            if (
                this.state.currencies_list_priority[i].id ===
                this.state.currency_to_id
            ) {
                currency_to_name = this.state.currencies_list_priority[i]
                    .short_name
                currency_to_flag = this.state.currencies_list_priority[i]
                    .iso_alpha_3
            }
        }

        return (
            <React.Fragment>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <HorizontalStepperNew
                            transfer_status={this.state.status}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <Card>
                            <CardHeader>
                                <h3 className={classes.pageHeadings}>
                                    Transfer Details
                                </h3>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableData={[
                                        [
                                            <span style={{fontWeight: 500}}>Transfer ID</span>,
                                            `${this.state.id}`],
                                        [
                                            <span style={{fontWeight: 500}}>Transaction Date</span>,
                                            `${moment(
                                                this.state.transaction_datetime
                                            ).format('DD/MM/YYYY')}`,
                                        ],
                                        [
                                            <span style={{fontWeight: 500}}>Settlement Date</span>,
                                            `${moment(
                                                this.state.settlement_date
                                            ).format('DD/MM/YYYY')}`,
                                        ],
                                        [
                                            <span style={{fontWeight: 500}}>Transfer From</span>,
                                            <Fragment>
                                                <img src={`https://wise.com/public-resources/assets/flags/rectangle/${currency_from_flag.toLowerCase()}.png`} width="24px" height="16px" />
                                                {` `}
                                                {currency_from_name}
                                                {` `}
                                                {this.props.intl.formatNumber(
                                                    this.state.amount_from,
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Fragment>,
                                        ],
                                        [
                                            <span style={{fontWeight: 500}}>Transfer To</span>,
                                            <Fragment>
                                                <img src={`https://wise.com/public-resources/assets/flags/rectangle/${currency_to_flag.toLowerCase()}.png`} width="24px" height="16px" />
                                                {` `}
                                                {currency_to_name}
                                                {` `}
                                                {this.props.intl.formatNumber(
                                                    this.state.amount_to,
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </Fragment>,
                                        ],
                                        [
                                            <span style={{fontWeight: 500}}>Rate</span>,
                                            `${this.props.intl.formatNumber(
                                                this.state.client_rate,
                                                {
                                                    maximumFractionDigits: 5,
                                                }
                                            )}`,
                                        ],
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                    {!this.state.clientHoldingAccountId && (

                                                                    // <TransferDetailSummary_Payout
                                                                    // small_version={true}
                                                                    // headingText={"Payment Details"}
                                                                    // transfer_id={
                                                                    //     this
                                                                    //         .props
                                                                    //         .transfer_detail_id
                                                                    // }
                                                                    // showHeader={false}
                                                                    // showFooter={true}
                                                                    // showDealDetails={false}
                                                                    // />        
                                                                    <>
                                                                    {this.state.isMultiPay && (
                                                                        <p>
                                                                            Multiple Payout Record<br/>
                                                                            Please refer to the "payout" tab above to view the payout details.
                                                                        </p>
                                                                    )}

                                                                    {!this.state.isMultiPay && (
                                                                    <TransferDetailPayoutSingle
                                                                    small_version={true}
                                                                    headingText={"Payment Details"}
                                                                    transfer_id={
                                                                        this
                                                                            .props
                                                                            .transfer_detail_id
                                                                    }
                                                                    showHeader={false}
                                                                    showFooter={false}
                                                                    showDealDetails={false}
                                                                    />   
                                                                    )}
                                                                    </>
                                                                    

                                                            )}
                                                            {this.state
                                                                .clientHoldingAccountId && (
                                                                <TransferDetailPayoutToLedger />
                                                            )}
                    </GridItem>
                </GridContainer>
            </React.Fragment>
        )
    }

    //isChecking function for #612 by Tom
    checkNullRemitBankAccId(input_remitting_bank_id) {
        //console.log(input_remitting_bank_id);
        if (input_remitting_bank_id > 0) {
            return true
        } else {
            return false
        }
    }

    queryStrings() {
        const query_strings = queryString.parse(this.props.location.search)
        if (Object.keys(query_strings).length !== 0) {
            return query_strings.id
        }
        return 0
    }

    getActive = () => {
        const query_strings = queryString.parse(this.props.location.search);
        if (query_strings) {
          switch (query_strings.tab) {
            case 'summary':
              this.setState({navpill_active: 0});
              break;
            case 'remittance':
              this.setState({navpill_active: 1});
              break;
            default:
              this.setState({navpill_active: 0});
              break;
          }
          // const search = this.props.location.search;
          // if (search.split('=')[0] == '?tab') {
          //   if (search.split('=')[1] == 'payouts') {
          //     return 5;
          //   }
        }
        // return 0;
      };
    

    render() {
        const { classes } = this.props
        if (!this.state.loaded) {
            return null
        }

        //#841 Tom's solution
        let isCompleted = false
        if (this.state.loaded) {
            // console.log(this.state.transfer_status);
            // console.log(completedFlag);
            this.state.transfer_status.forEach(function(entry) {
                if (entry.status_id == 8) {
                    isCompleted = true
                } else {
                    isCompleted = false
                }
            })
            // console.log(isCompleted)
        }
        //end of #841 Tom's solution

        const payoutTab = {
            tabButton: 'Payout',
            tabContent: (
                <TransferDetailPayout
                    edit_mode={
                        this.state.edit_mode
                    }
                    transfer_id={
                        this.props
                            .transfer_detail_id
                    }
                    transfer_nickname={
                        this.state.nickname
                    }
                    beneficiary_id={
                        this.state
                            .beneficiary_id
                    }
                    currency_to_id={
                        this.state
                            .currency_to_id
                    }
                    amount_to={
                        this.state.amount_to
                    }
                    client_id={
                        this.state.client_id
                    }
                    current_staff_super_admin={
                        this.props.app_state
                            .current_staff_super_admin
                    }
                    isCompleted={
                        isCompleted
                    }
                    transfer_status={
                        this.state
                            .transfer_status
                    }
                    status={
                        this.state.status
                    }
                />
            ),
        }

        let remittanceTab = isCompleted
            ? {}
            : {
                  tabButton: 'Remittance',
                  tabContent: (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <HorizontalStepperNew
                                    transfer_status={this.state.status}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                        <TransferDetailRemittance
                            no_stepper={true}
                            no_admin={true}
                            transfer_id={this.props.transfer_detail_id}
                            remitting_bank_id={this.state.remitting_bank_id}
                            currency_from_id={this.state.currency_from_id}
                            amount_from={this.state.amount_from}
                            rate={this.state.client_rate}
                            currency_to_id={this.state.currency_to_id}
                            amount_to={this.state.amount_to}
                            client_id={this.state.client_id}
                            current_staff_super_admin={
                                this.props.app_state.current_staff_super_admin
                            }
                            transfer_status={this.state.transfer_status}
                            ledgerFrom={this.state.ledgerFrom}
                            fee_visible_amount={this.state.fee_visible_amount}
                        />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <TransferDetailPayoutSinglePopUp
                                small_version={true}
                                headingText={"Payout Details"}
                                showHeader={true}
                                showLogo={false}
                                // showFooter={true}
                                showFooter={false}
                                showDealDetails={true}

                                                                    edit_mode={
                                                                        this
                                                                            .state
                                                                            .edit_mode
                                                                    }
                                                                    transfer_id={
                                                                        this
                                                                            .props
                                                                            .transfer_detail_id
                                                                    }
                                                                    transfer_nickname={
                                                                        this
                                                                            .state
                                                                            .nickname
                                                                    }
                                                                    beneficiary_id={
                                                                        this
                                                                            .state
                                                                            .beneficiary_id
                                                                    }
                                                                    currency_from_id={
                                                                        this
                                                                            .state
                                                                            .currency_from_id
                                                                    }
                                                                    currency_to_id={
                                                                        this
                                                                            .state
                                                                            .currency_to_id
                                                                    }
                                                                    amount_from={
                                                                        this
                                                                            .state
                                                                            .amount_from
                                                                    }
                                                                    amount_to={
                                                                        this
                                                                            .state
                                                                            .amount_to
                                                                    }
                                                                    rate={
                                                                        this
                                                                            .state
                                                                            .client_rate
                                                                    }
                                                                    settlement_date={
                                                                        this
                                                                            .state
                                                                            .settlement_date
                                                                    }
                                                                    client_id={
                                                                        this
                                                                            .state
                                                                            .client_id
                                                                    }
                                                                    payout_datetime={
                                                                        this
                                                                            .state
                                                                            .payout_datetime
                                                                    }
                                                                    payout_bank_reference={
                                                                        this
                                                                            .state
                                                                            .payout_bank_reference
                                                                    }
                                                                    transfer_status={
                                                                        this
                                                                            .state
                                                                            .transfer_status
                                                                    }
                                                                    current_staff_super_admin={
                                                                        this
                                                                            .props
                                                                            .app_state
                                                                            .current_staff_super_admin
                                                                    }
                                                                    isCompleted={
                                                                        isCompleted
                                                                    }
                                                                    status={
                                                                        this
                                                                            .state
                                                                            .status
                                                                    }
                                                                    // transfer_status={this.state.transfer_status}
                                                                    //language_id={this.state.current_transfer_client.language_id}
                                                                />
                        </GridItem>
                      </GridContainer>
                  ),
              }

        let transferDocumentsTab = {
            tabButton: 'Documents',
            tabContent: <TransferDocuments transfer_id={this.props.transfer_detail_id} state={this.props.app_state} />
        }

        // if (this.checkNullRemitBankAccId(this.state.remitting_bank_id)) {
            //return tab content with Remittance, work around for #612 by Tom
            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <h4 style={{ paddingTop: 16 }}>
                                        <strong>
                                            Trade ID - TR
                                            {this.props.transfer_detail_id.padStart(
                                                5,
                                                '0'
                                            )}
                                            .{'  '}
                                            {/* {this.state.nickname} */}
                                            {isCompleted
                                                ? ' Status - Completed'
                                                : ''}
                                        </strong>
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <NavPills
                                        // active={this.queryStrings()}
                                        active={this.state.navpill_active}
                                        color="info"
                                        tabs={[
                                            {
                                                tabButton: 'Summary',
                                                tabContent: (
                                                    <React.Fragment>
                                                        <div
                                                            style={{
                                                                padding: 20,
                                                            }}
                                                        >
                                                            {this.getNavPill_Summary()}
                                                            
                                                        </div>
                                                    </React.Fragment>
                                                ),
                                            },
                                            remittanceTab,
                                            payoutTab,
                                            transferDocumentsTab
                                        ]}
                                    />
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            )
        // } else {
        //     return (
        //         <div>
        //             <GridContainer>
        //                 <GridItem xs={12} sm={12} md={12}>
        //                     <Card>
        //                         <CardHeader color="rose" icon>
        //                             <h4 className={classes.cardIconTitle}>
        //                                 Editing {this.state.clients_firstname}{' '}
        //                                 {this.state.clients_lastname} [
        //                                 {this.props.transfer_detail_id}]
        //                             </h4>
        //                             <div className={classes.flexEnd}>
        //                                 <h4 className={classes.cardIconTitle}>
        //                                     {this.state.edit_mode
        //                                         ? `Edit Record: `
        //                                         : `View Record: `}
        //                                     {this.state.first_name}
        //                                     {` `}
        //                                     {this.state.last_name}
        //                                     {` `}
        //                                 </h4>
        //                             </div>
        //                         </CardHeader>
        //                         <CardBody>
        //                             <NavPills
        //                                 color="info"
        //                                 tabs={[
        //                                     {
        //                                         tabButton: 'Summary',
        //                                         tabContent: (
        //                                             <React.Fragment>
        //                                                 <div
        //                                                     style={{
        //                                                         padding: 20,
        //                                                     }}
        //                                                 >
        //                                                     {this.getNavPill_Summary()}
        //                                                 </div>
        //                                             </React.Fragment>
        //                                         ),
        //                                     },
        //                                     // {
        //                                     //   tabButton: "Remittance",
        //                                     //   tabContent: (
        //                                     //     <TransferDetailRemittance
        //                                     //       transfer_id={this.props.transfer_detail_id}
        //                                     //       remitting_bank_id={this.state.remitting_bank_id}
        //                                     //       currency_from_id={this.state.currency_from_id}
        //                                     //       amount_from={this.state.amount_from}
        //                                     //       rate={this.state.client_rate}
        //                                     //       currency_to_id={this.state.currency_to_id}
        //                                     //       amount_to={this.state.amount_to}
        //                                     //       client_id={this.state.client_id}
        //                                     //       current_staff_super_admin={this.props.app_state.current_staff_super_admin}
        //                                     //     />
        //                                     //   )
        //                                     // },
        //                                     {
        //                                         tabButton: 'Payout',
        //                                         tabContent: (
        //                                             <TransferDetailPayout
        //                                                 edit_mode={
        //                                                     this.state.edit_mode
        //                                                 }
        //                                                 transfer_id={
        //                                                     this.props
        //                                                         .transfer_detail_id
        //                                                 }
        //                                                 transfer_nickname={
        //                                                     this.state.nickname
        //                                                 }
        //                                                 beneficiary_id={
        //                                                     this.state
        //                                                         .beneficiary_id
        //                                                 }
        //                                                 currency_to_id={
        //                                                     this.state
        //                                                         .currency_to_id
        //                                                 }
        //                                                 amount_to={
        //                                                     this.state.amount_to
        //                                                 }
        //                                                 client_id={
        //                                                     this.state.client_id
        //                                                 }
        //                                                 current_staff_super_admin={
        //                                                     this.props.app_state
        //                                                         .current_staff_super_admin
        //                                                 }
        //                                                 isCompleted={
        //                                                     isCompleted
        //                                                 }
        //                                                 transfer_status={
        //                                                     this.state
        //                                                         .transfer_status
        //                                                 }
        //                                                 status={
        //                                                     this.state.status
        //                                                 }
        //                                             />
        //                                         ),
        //                                     },
        //                                 ]}
        //                             />
        //                             <Clearfix />
        //                         </CardBody>
        //                     </Card>
        //                 </GridItem>
        //             </GridContainer>
        //         </div>
        //     )
        // }
    }
}

TransferEdit.propTypes = {
    classes: PropTypes.object.isRequired,
}

// export default injectIntl(withStyles(staffEditStyles)(TransferEdit));

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        clients: state.clients,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // loadClientDetailsRequest: () => {
        //   dispatch(loadClientDetailsRequest())
        // },
        // loadClientDetailsSuccess: () => {
        //   dispatch(loadClientDetailsSuccess())
        // },
        loadTransferDetailsRequest: () => {
            dispatch(loadTransferDetailsRequest())
        },
        loadTransferDetailsSuccess: () => {
            dispatch(loadTransferDetailsSuccess())
        },
    }
}

const TransferEditContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(withStyles(staffEditStyles)(TransferEdit)))

export default TransferEditContainer
