import React from 'react'
import PropTypes from 'prop-types'

import Datetime from 'react-datetime'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
// import Card from "../../components/Card/Card.jsx";
// import CardBody from "../../components/Card/CardBody.jsx";

// import CardHeader from "../../components/Card/CardHeader";
// import CardIcon from "../../components/Card/CardIcon";
// import PermIdentity from "@material-ui/icons/PermIdentity";
import CustomInput from '../CustomInput/CustomInput'
import Button from '../CustomButtons/Button'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardHeader from '../Card/CardHeader'
import FormControl from '@material-ui/core/FormControl'

import selectStyles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react.jsx'

import InputLabel from '@material-ui/core/InputLabel'
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import { API } from 'aws-amplify'
import IdentificationUploadContainer from './IdentificationUpload/IdentificationUploadContainer.js'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
// For CustomReactSelect
import CustomReactSelect from '../../components/Forms/CustomReactSelect/CustomReactSelect.jsx'

import passportImage from 'assets/img/identifications/sample_passport.jpg'
import driversLicenceImage from 'assets/img/identifications/sample_drivers_vic.jpg'
import medicareImage from 'assets/img/identifications/sample_medicare.jpg'
import visaImage from 'assets/img/identifications/sample_visa.jpg'
import birthRecordImage from 'assets/img/identifications/sample_birthcertificate.jpg'
import marriageRecordImage from 'assets/img/identifications/sample_marriagecertificate.jpg'

import { FormattedMessage } from 'react-intl'

const styles = {
    ...selectStyles,
    ...extendedFormsStyle,
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400',
        },
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center',
    },
    description: {
        color: '#999999',
    },
    updateProfileButton: {
        float: 'right',
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none',
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px',
    },
    registerButton: {
        float: 'right',
    },
}

class IdentificationNew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // register form
            // client_list: [],
            document_category_list: [],

            // form_valid: false,
            document_category: {},
            document_category_id: '',
            document_category_id_state: '',
            document_number: '',
            document_number_state: '',
            document_detail: '',
            document_detail_state: '',
            document_expiry: '',
            document_expiry_state: '',
            document_expiry_init_for_category_change: false,
            document_issue_state: '',
            document_issue_state_state: '',
            document_issue_country: '',
            document_issue_country_state: '',
            document_upload_status: 'Document has not been uploaded',

            //initial set id_status = 2
            id_status: 2,

            nickname: '',
            nickname_state: '',
            newUser: null,
            country_list: [],
            country_list_prio: [],
        }
    }

    componentDidMount() {
        this.props.startNewIdentificationRecordCreation()
        // API.get("clients", `/get-list-not-deleted`)
        //     .then(response => {
        //         // console.log(response);
        //         this.setState({
        //             client_list: response
        //         });
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
        API.get('identification', `/get/categories`)
            .then(response => {
                // console.log(response);
                this.setState({
                    document_category_list: response,
                })
            })
            .catch(error => {
                console.log(error)
            })

        API.get('countries', `/countries/list_not_deleted`)
            .then(response => {
                this.setState({
                    // country_list: response.fullList,
                    country_list_prio: response,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    setDefaultNickname(defaultNickname) {
        if (
            this.state.nickname_state === '' ||
            (this.state.nickname_state !==
                'success_' + this.state.document_category.nickname &&
                this.state.nickname_state !==
                    'error_' + this.state.document_category.nickname)
        ) {
            this.setState(
                {
                    nickname_state:
                        'error_' + this.state.document_category.nickname,
                },
                () => {
                    if (
                        this.state.nickname_state ===
                        'error_' + this.state.document_category.nickname
                    ) {
                        this.setState({ nickname: defaultNickname })
                        this.props.updateNewIdentificationRecordCreation(
                            'nickname',
                            defaultNickname
                        )
                        this.setState({
                            nickname_state:
                                'success_' +
                                this.state.document_category.nickname,
                        })
                    }
                }
            )
        }
    }

    validateForm() {
        // console.log(this.state)
        // console.log(!(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id))
        const defaultPassportNickname = 'My Passport'
        const defaultDriversLicenceNickname = 'My Drivers Licence'
        const defaultMedicareCardNickname = 'My Medicare Card'
        const defaultVisaNickname = 'My Visa'
        const defaultBirthRecordNickname = 'My Birth Record'
        const defaultMarriageRecordNickname = 'My Marriage Record'

        if (this.state.document_category_id_state === '') {
            this.setState({ document_category_id_state: 'error' })
        }

        switch (this.state.document_category_id) {
            case 1: // Passport
                if (this.state.document_number_state === '') {
                    this.setState({ document_number_state: 'error' })
                }
                if (this.state.document_expiry_state === '') {
                    this.setState({ document_expiry_state: 'error' })
                }
                // if (this.state.document_detail_state === "") {
                //   this.setState({document_detail_state: "error"});
                // }
                if (this.state.document_issue_state_state === 'error') {
                    this.setState({ document_issue_state_state: '' })
                }
                if (this.state.document_issue_country_state === '') {
                    this.setState({ document_issue_country_state: 'error' })
                }
                this.setDefaultNickname(defaultPassportNickname)
                if (
                    this.state.document_number_state === 'success' &&
                    this.state.document_expiry_state === 'success' &&
                    // this.state.document_detail_state === "success" &&
                    // this.state.document_issue_state_state === "success" &&
                    this.state.document_issue_country_state === 'success' &&
                    this.state.nickname_state ===
                        'success_' + this.state.document_category.nickname
                    // !(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id)
                ) {
                    return true
                } else {
                    return false
                }
                // eslint-disable-next-line no-unreachable
                break
            case 2: // Drivers Licence
                if (this.state.document_number_state === '') {
                    this.setState({ document_number_state: 'error' })
                }
                if (this.state.document_expiry_state === '') {
                    this.setState({ document_expiry_state: 'error' })
                }
                if (this.state.document_detail_state === 'error') {
                    this.setState({ document_detail_state: '' })
                }
                if (this.state.document_issue_state_state === '') {
                    this.setState({ document_issue_state_state: 'error' })
                }
                if (this.state.document_issue_country_state === '') {
                    this.setState({ document_issue_country_state: 'error' })
                }
                this.setDefaultNickname(defaultDriversLicenceNickname)
                if (
                    this.state.document_number_state === 'success' &&
                    this.state.document_expiry_state === 'success' &&
                    // this.state.document_detail_state === "success" &&
                    this.state.document_issue_state_state === 'success' &&
                    this.state.document_issue_country_state === 'success' &&
                    this.state.nickname_state ===
                        'success_' + this.state.document_category.nickname
                    // !(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id)
                ) {
                    return true
                } else {
                    return false
                }
                // eslint-disable-next-line no-unreachable
                break
            case 3: // Medicare Card
                if (this.state.document_number_state === '') {
                    this.setState({ document_number_state: 'error' })
                }
                if (this.state.document_expiry_state === '') {
                    this.setState({ document_expiry_state: 'error' })
                }
                if (this.state.document_detail_state === 'error') {
                    this.setState({ document_detail_state: '' })
                }
                if (this.state.document_issue_state_state === 'error') {
                    this.setState({ document_issue_state_state: '' })
                }
                if (this.state.document_issue_country_state === '') {
                    this.setState({ document_issue_country_state: 'error' })
                }
                this.setDefaultNickname(defaultMedicareCardNickname)
                if (
                    this.state.document_number_state === 'success' &&
                    this.state.document_expiry_state === 'success' &&
                    // this.state.document_detail_state === "success" &&
                    // this.state.document_issue_state_state === "success" &&
                    this.state.document_issue_country_state === 'success' &&
                    this.state.nickname_state ===
                        'success_' + this.state.document_category.nickname
                    // !(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id)
                ) {
                    return true
                } else {
                    return false
                }
                // eslint-disable-next-line no-unreachable
                break
            case 4: // Visa
                if (this.state.document_number_state === '') {
                    this.setState({ document_number_state: 'error' })
                }
                if (this.state.document_expiry_state === '') {
                    this.setState({ document_expiry_state: 'error' })
                }
                if (this.state.document_detail_state === 'error') {
                    this.setState({ document_detail_state: '' })
                }
                if (this.state.document_issue_state_state === 'error') {
                    this.setState({ document_issue_state_state: '' })
                }
                if (this.state.document_issue_country_state === '') {
                    this.setState({ document_issue_country_state: 'error' })
                }
                this.setDefaultNickname(defaultVisaNickname)
                if (
                    this.state.document_number_state === 'success' &&
                    this.state.document_expiry_state === 'success' &&
                    // this.state.document_detail_state === "success" &&
                    // this.state.document_issue_state_state === "success" &&
                    this.state.document_issue_country_state === 'success' &&
                    this.state.nickname_state ===
                        'success_' + this.state.document_category.nickname
                    // !(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id)
                ) {
                    return true
                } else {
                    return false
                }
                // eslint-disable-next-line no-unreachable
                break
            case 5: // Birth Record
                if (this.state.document_number_state === '') {
                    this.setState({ document_number_state: 'error' })
                }
                if (this.state.document_expiry_state === 'error') {
                    this.setState({ document_expiry_state: '' })
                }
                if (this.state.document_detail_state === 'error') {
                    this.setState({ document_detail_state: '' })
                }
                if (this.state.document_issue_state_state === '') {
                    this.setState({ document_issue_state_state: 'error' })
                }
                if (this.state.document_issue_country_state === '') {
                    this.setState({ document_issue_country_state: 'error' })
                }
                this.setDefaultNickname(defaultBirthRecordNickname)
                if (
                    this.state.document_number_state === 'success' &&
                    // this.state.document_expiry_state === "success" &&
                    // this.state.document_detail_state === "success" &&
                    this.state.document_issue_state_state === 'success' &&
                    this.state.document_issue_country_state === 'success' &&
                    this.state.nickname_state ===
                        'success_' + this.state.document_category.nickname
                    // !(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id)
                ) {
                    return true
                } else {
                    return false
                }
                // eslint-disable-next-line no-unreachable
                break
            case 7: // Marriage Record
                if (this.state.document_number_state === '') {
                    this.setState({ document_number_state: 'error' })
                }
                if (this.state.document_expiry_state === 'error') {
                    this.setState({ document_expiry_state: '' })
                }
                if (this.state.document_detail_state === 'error') {
                    this.setState({ document_detail_state: '' })
                }
                if (this.state.document_issue_state_state === 'error') {
                    this.setState({ document_issue_state_state: '' })
                }
                if (this.state.document_issue_country_state === '') {
                    this.setState({ document_issue_country_state: 'error' })
                }
                this.setDefaultNickname(defaultMarriageRecordNickname)
                if (
                    this.state.document_number_state === 'success' &&
                    // this.state.document_expiry_state === "success" &&
                    // this.state.document_detail_state === "success" &&
                    // this.state.document_issue_state_state === "success" &&
                    this.state.document_issue_country_state === 'success' &&
                    this.state.nickname_state ===
                        'success_' + this.state.document_category.nickname
                    // !(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id)
                ) {
                    return true
                } else {
                    return false
                }
                // eslint-disable-next-line no-unreachable
                break
            default:
                if (this.state.document_number_state === 'error') {
                    this.setState({ document_number_state: '' })
                }
                if (this.state.document_expiry_state === 'error') {
                    this.setState({ document_expiry_state: '' })
                }
                if (this.state.document_detail_state === 'error') {
                    this.setState({ document_detail_state: '' })
                }
                if (this.state.document_issue_state_state === 'error') {
                    this.setState({ document_issue_state_state: '' })
                }
                if (this.state.document_issue_country_state === 'error') {
                    this.setState({ document_issue_country_state: '' })
                }
                if (this.state.nickname_state === 'error') {
                    this.setState({ nickname_state: '' })
                }
                return false
        }
    }

    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (emailRex.test(value)) {
            return true
        }
        return false
    }

    compare(string1, string2) {
        if (string1 === string2) {
            return true
        }
        return false
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        switch (type) {
            case 'email':
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            case 'password':
                if (this.verifyLength(event.target.value, 1)) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            case 'equalTo':
                if (
                    this.compare(
                        event.target.value,
                        this.state[stateNameEqualTo]
                    )
                ) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            case 'checkbox':
                if (event.target.checked) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            case 'number':
                if (this.verifyNumber(event.target.value)) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    if (stateName === 'nickname') {
                        this.setState({
                            [stateName + '_state']:
                                'success_' +
                                this.state.document_category.nickname,
                        })
                    } else {
                        this.setState({ [stateName + '_state']: 'success' })
                    }
                } else {
                    if (stateName === 'nickname') {
                        this.setState({
                            [stateName + '_state']:
                                'error_' +
                                this.state.document_category.nickname,
                        })
                    } else {
                        this.setState({ [stateName + '_state']: 'error' })
                    }
                }
                break
            case 'max-length':
                if (
                    !this.verifyLength(event.target.value, stateNameEqualTo + 1)
                ) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            case 'url':
                if (this.verifyUrl(event.target.value)) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            case 'min-value':
                if (
                    this.verifyNumber(event.target.value) &&
                    event.target.value >= stateNameEqualTo
                ) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            case 'max-value':
                if (
                    this.verifyNumber(event.target.value) &&
                    event.target.value <= stateNameEqualTo
                ) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            case 'range':
                if (
                    this.verifyNumber(event.target.value) &&
                    event.target.value >= stateNameEqualTo &&
                    event.target.value <= maxValue
                ) {
                    this.setState({ [stateName + 'State']: 'success' })
                } else {
                    this.setState({ [stateName + 'State']: 'error' })
                }
                break
            default:
                break
        }
        switch (type) {
            case 'checkbox':
                this.setState({ [stateName]: event.target.checked })
                break
            default:
                this.setState({
                    [stateName]: event.target.value,
                })
                break
        }
        // eslint-disable-next-line react/prop-types
        this.props.updateNewIdentificationRecordCreation(
            stateName,
            event.target.value
        )
    }

    handleSelectChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })

        if (event.target.name === 'document_category_id') {
            // console.log(event.target.value);
            // console.log(this.state.document_category_list);
            for (var key in this.state.document_category_list) {
                if (
                    this.state.document_category_list[key].id ===
                    event.target.value
                ) {
                    this.setState({
                        document_category: this.state.document_category_list[
                            key
                        ],
                        document_detail_name:
                            this.state.document_category_list[key]
                                .document_detail || null,
                        document_category_id_state: 'success',
                    })
                }
            }
            // eslint-disable-next-line react/prop-types
            this.props.updateNewIdentificationRecordCreation(
                'document_category_id',
                event.target.value
            )
        }

        if (event.target.name === 'document_issue_country') {
            // eslint-disable-next-line react/prop-types
            this.props.updateNewIdentificationRecordCreation(
                'document_issue_country',
                event.target.value
            )
            this.setState({ document_issue_country_state: 'success' })
        }
    }

    // For CustomReactSelect
    handleCustomReactSelectChange = name => value => {
        var option_value
        if (value === null) {
            option_value = null
        } else {
            option_value = value.value
        }
        this.setState({
            [name]: option_value,
        })
        if (name === 'document_category_id') {
            for (var key in this.state.document_category_list) {
                if (
                    this.state.document_category_list[key].id === option_value
                ) {
                    this.setState({
                        document_category: this.state.document_category_list[
                            key
                        ],
                        document_detail_name:
                            this.state.document_category_list[key]
                                .document_detail || null,
                        document_category_id_state: 'success',
                    })
                }
            }
            // eslint-disable-next-line react/prop-types
            this.props.updateNewIdentificationRecordCreation(
                'document_category_id',
                option_value
            )
            // When changing a document category, clear the document_expiry data and chagne the document_expiry_init_for_category_change state
            if (this.state.document_expiry_init_for_category_change !== true) {
                this.setState({
                    document_expiry_init_for_category_change: true,
                    document_expiry: '',
                    document_expiry_state: 'error',
                })
                // eslint-disable-next-line react/prop-types
                this.props.updateNewIdentificationRecordCreation(
                    'document_expiry',
                    ''
                )
            }
        }
        if (name === 'document_issue_country') {
            this.setState({
                document_issue_country_state: 'success',
            })
            // eslint-disable-next-line react/prop-types
            this.props.updateNewIdentificationRecordCreation(
                'document_issue_country',
                option_value
            )
        }
    }

    handleExpiryDateChange = (event, option) => {
        let selDate = ''
        if (event.isValid != undefined) {
            if (event.toDate().getMonth() >= 9) {
                if (option === 'onlyYearMonth')
                    selDate =
                        event.toDate().getFullYear() +
                        '-' +
                        (event.toDate().getMonth() + 1) +
                        '-01'
                // set it as the first day of the month
                else {
                    if (event.toDate().getDate() > 9)
                        selDate =
                            event.toDate().getFullYear() +
                            '-' +
                            (event.toDate().getMonth() + 1) +
                            '-' +
                            event.toDate().getDate()
                    // + 'T00:00.00.000Z';
                    else
                        selDate =
                            event.toDate().getFullYear() +
                            '-' +
                            (event.toDate().getMonth() + 1) +
                            '-0' +
                            event.toDate().getDate() // + 'T00:00.00.000Z';
                }
            } else if (event.toDate().getMonth() < 9) {
                if (option === 'onlyYearMonth')
                    selDate =
                        event.toDate().getFullYear() +
                        '-0' +
                        (event.toDate().getMonth() + 1) +
                        '-01'
                // set it as the first day of the month
                else {
                    if (event.toDate().getDate() > 9)
                        selDate =
                            event.toDate().getFullYear() +
                            '-0' +
                            (event.toDate().getMonth() + 1) +
                            '-' +
                            event.toDate().getDate()
                    // + 'T00:00.00.000Z';
                    else
                        selDate =
                            event.toDate().getFullYear() +
                            '-0' +
                            (event.toDate().getMonth() + 1) +
                            '-0' +
                            event.toDate().getDate() // + 'T00:00.00.000Z';
                }
            }
            this.setState({ document_expiry_state: 'success' })
        } else {
            selDate = event
            this.setState({ document_expiry_state: 'error' })
        }
        this.setState({ document_expiry: selDate })
        // eslint-disable-next-line react/prop-types
        this.props.updateNewIdentificationRecordCreation(
            'document_expiry',
            selDate
        )
        this.setState({ document_expiry_init_for_category_change: false })
    }

    /* function for #737 automatically fill in "Document Nickname" field
     * Input: id (when user selects "passport" from drop down)
     * Output: LabelText about DocumentNickname
     * 
     * Author: Tom 
     */
    handleLabeltextDocumentNickname = input => {
        let output = ''

        switch (input) {
            case 1:
                output = "'Document Nickname(eg 'My Passport')'"
                break
            case 2:
                output = "'Document Nickname(eg 'My Drivers Licence')'"
                break
            case 3:
                output = "'Document Nickname(eg 'My Medicare')'"
                break
            case 4:
                output = "'Document Nickname(eg 'My Visa')'"
                break
            case 5:
                output = "'Document Nickname(eg 'My Birth Record')'"
                break
            case 6:
                output = "'Document Nickname(eg 'My Marriage Record')'"
                break
            default:
                output = "'Document Nickname(eg 'My Passport')'"
                break
        }
        return output
    }

    handleSubmitForm() {
        if (this.validateForm()) {
            // eslint-disable-next-line react/prop-types
            this.props.createNewIdentificationRecord(
                // eslint-disable-next-line react/prop-types
                this.props.identification.new_identification_record_data
            )
        } else {
            return
        }
        // eslint-disable-next-line react/prop-types
        this.props.history.push(`/identification/list`)
    }

    handleCancel() {
        // eslint-disable-next-line react/prop-types
        this.props.history.push(`/identification/list`)
    }

    getDocumentHelp(document_category_id) {
        switch (document_category_id) {
            case 1:
                return (
                    <React.Fragment>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <h6>When providing details of a Passport</h6>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <img
                                    src={passportImage}
                                    alt="Passport"
                                    style={{ width: '100%' }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8}>
                                <p>
                                    When using a passport for identification, we
                                    require
                                </p>
                                <ul>
                                    <li>Document number</li>
                                    <li>Expiry date</li>
                                    <li>MRZ Line</li>
                                    <li>Country of issue</li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
                // eslint-disable-next-line no-unreachable
                break
            case 2:
                return (
                    <React.Fragment>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <h6>
                                    When providing details of a Drivers Licnece
                                </h6>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <img
                                    src={driversLicenceImage}
                                    alt="Drivers licence"
                                    style={{ width: '100%' }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8}>
                                <p>
                                    When using a drivers licence for
                                    identification, we require
                                </p>
                                <ul>
                                    <li>Document number</li>
                                    <li>Expiry date</li>
                                    <li>State or territory of issue</li>
                                    <li>Country of issue</li>
                                    <li>
                                        Please note we
                                        require two uploads (the front and back
                                        of the licence)
                                    </li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
                // eslint-disable-next-line no-unreachable
                break
            case 3:
                return (
                    <React.Fragment>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <h6>
                                    When providing details of a Medicare Card
                                </h6>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <img
                                    src={medicareImage}
                                    alt="Medicare card"
                                    style={{ width: '100%' }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8}>
                                <p>
                                    When using a Medicare card for
                                    identification, we require
                                </p>
                                <ul>
                                    <li>Document number</li>
                                    <li>Expiry date (month and year only)</li>
                                    <li>Country of issue</li>
                                    {/* Remove Expiry date for now because we need to deal with month and year only format */}
                                    {/* <li>Expiry date (month and year only)</li> */}
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
                // eslint-disable-next-line no-unreachable
                break
            case 4:
                return (
                    <React.Fragment>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <h6>When providing details of a Visa</h6>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <img
                                    src={visaImage}
                                    alt="Visa"
                                    style={{ width: '100%' }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8}>
                                <p>
                                    When using a visa for identification, we
                                    require
                                </p>
                                <ul>
                                    <li>Document number (visa number)</li>
                                    <li>Expiry date (stay until date)</li>
                                    <li>Country of issue</li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
                // eslint-disable-next-line no-unreachable
                break
            case 5:
                return (
                    <React.Fragment>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <h6>
                                    When providing details of a Bank Statement
                                </h6>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                {/* <img
                                    src={birthRecordImage}
                                    alt="Birth record"
                                    style={{ width: '100%' }}
                                /> */}
                            </GridItem>
                            <GridItem xs={12} sm={8}>
                                <p>When using a bank statement for proof of address, we require</p>
                                <ul>
                                    <li>The top of the statement</li>
                                    <li>Including full bank details</li>
                                    <li>We do not need to see your balance</li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
                // eslint-disable-next-line no-unreachable
                break
            case 7:
                return (
                    <React.Fragment>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <h6>When providing details of a Birth or Marriage Record</h6>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            {/* <GridItem xs={12} sm={2}>
                </GridItem> */}
                            <GridItem xs={12} sm={4}>
                                <img
                                    src={marriageRecordImage}
                                    alt="Marriage record"
                                    style={{ width: '100%' }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8}>
                                <p>When using a birth or marriage record for identification, we require</p>
                                <ul>
                                    <li>Document number</li>
                                    <li>Country of issue</li>
                                </ul>
                            </GridItem>
                        </GridContainer>
                    </React.Fragment>
                )
                // eslint-disable-next-line no-unreachable
                break
            default:
                return <React.Fragment />
                // eslint-disable-next-line no-unreachable
                break
        }
    }

    displayYearMonthDate() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <InputLabel
                    className={classes.label}
                    error={this.state.document_expiry_state === 'error'}
                >
                    Document Expiry
                </InputLabel>
                <FormControl fullWidth className={classes.selectFormControl}>
                    <Datetime
                        timeFormat={false}
                        dateFormat={'DD/MM/YYYY'}
                        // The following method which can hide data doesn't work for the first date pick
                        // dateFormat={!this.state.document_expiry_init_for_category_change && 'DD/MM/YYYY'}
                        // dateFormat={(this.props.identification.new_identification_record_data && this.props.identification.new_identification_record_data.document_expiry === "") ? false : 'DD/MM/YYYY'}
                        closeOnSelect={true}
                        onChange={event => {
                            this.handleExpiryDateChange(event)
                        }}
                    />
                </FormControl>
                {this.state.document_expiry_state === 'error' ? (
                    <span>
                        <small style={{ color: 'red' }}>
                            Format: DD/MM/YYYY
                        </small>
                    </span>
                ) : (
                    <span>
                        <small>Format: DD/MM/YYYY</small>
                    </span>
                )}
            </React.Fragment>
        )
    }

    displayYearMonth() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <InputLabel
                    className={classes.label}
                    error={this.state.document_expiry_state === 'error'}
                >
                    Document Expiry
                </InputLabel>
                <FormControl fullWidth className={classes.selectFormControl}>
                    <Datetime
                        timeFormat={false}
                        dateFormat={'MM/YYYY'}
                        closeOnSelect={true}
                        onChange={event => {
                            this.handleExpiryDateChange(event, 'onlyYearMonth')
                        }}
                    />
                </FormControl>
                {this.state.document_expiry_state === 'error' ? (
                    <span>
                        <small style={{ color: 'red' }}>Format: MM/YYYY</small>
                    </span>
                ) : (
                    <span>
                        <small>Format: MM/YYYY</small>
                    </span>
                )}
            </React.Fragment>
        )
    }

    render() {
        const { classes } = this.props
        const form_valid = this.validateForm()
        // For CustomReactSelect. Generate select options for dropdown list.
        const document_category_select_options = this.state.document_category_list.map(
            item => ({
                value: item.id,
                label: item.nickname,
            })
        )
        const select_country_select_options = this.state.country_list_prio.map(
            item => ({
                value: item.id,
                label: item.full_name,
            })
        )

        let date_time_component = null
        if (this.state.document_category_id === 3) {
            date_time_component = this.displayYearMonth()
        } else {
            date_time_component = this.displayYearMonthDate()
        }
        // console.log(!(!this.props.identification.new_identification_record_data || !this.props.identification.new_identification_record_data.file_id));
        return (
            <GridContainer>
                <GridItem xs={12} sm={12}>
                    <h3>
                        <FormattedMessage
                            id="newIdentification.title"
                            defaultMessage={`Create a new identification record`}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id="newIdentification.subtitle"
                            defaultMessage={`Create a new record containing details and an upload of an identification document.`}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id="newIdentification.helptext"
                            defaultMessage={`This will be applied to the specified client account and used for verification purposes.`}
                        />
                    </p>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={6}>
                            <Card>
                                <CardHeader>
                                    <h5>Document Details</h5>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} md={6}>
                                            <CustomReactSelect
                                                label="Document Category"
                                                options={
                                                    document_category_select_options
                                                }
                                                value={
                                                    this.state
                                                        .document_category_id
                                                }
                                                onChange={this.handleCustomReactSelectChange(
                                                    'document_category_id'
                                                )}
                                                isClearable={false}
                                                // isDisabled={!this.state.edit_mode}
                                                error={
                                                    this.state
                                                        .document_category_id_state ===
                                                    'error'
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} md={6}>
                                            <CustomReactSelect
                                                label="Issue Country"
                                                options={
                                                    select_country_select_options
                                                }
                                                value={
                                                    this.state
                                                        .document_issue_country
                                                }
                                                onChange={this.handleCustomReactSelectChange(
                                                    'document_issue_country'
                                                )}
                                                isClearable={false}
                                                // isDisabled={!this.state.edit_mode}
                                                error={
                                                    this.state
                                                        .document_issue_country_state ===
                                                    'error'
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <CustomInput
                                        success={
                                            this.state.document_number_state ===
                                            'success'
                                        }
                                        error={
                                            this.state.document_number_state ===
                                            'error'
                                        }
                                        labelText="Document Number"
                                        id="document_number"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state.document_number,
                                            onChange: event =>
                                                this.change(
                                                    event,
                                                    'document_number',
                                                    'length',
                                                    3
                                                ),
                                            type: 'text',
                                        }}
                                    />
                                    {this.state.document_detail_name && (
                                        <CustomInput
                                            success={
                                                this.state
                                                    .document_detail_state ===
                                                'success'
                                            }
                                            error={
                                                this.state
                                                    .document_detail_state ===
                                                'error'
                                            }
                                            labelText={
                                                this.state
                                                    .document_detail_name ||
                                                'Document Detail'
                                            }
                                            id="document_detail"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: this.state
                                                    .document_detail,
                                                onChange: event =>
                                                    this.change(
                                                        event,
                                                        'document_detail',
                                                        'length',
                                                        3
                                                    ),
                                                type: 'text',
                                            }}
                                        />
                                    )}
                                    <GridContainer>
                                        <GridItem xs={12} md={6}>
                                            <CustomInput
                                                success={
                                                    this.state
                                                        .document_issue_state_state ===
                                                    'success'
                                                }
                                                error={
                                                    this.state
                                                        .document_issue_state_state ===
                                                    'error'
                                                }
                                                labelText="Issue State"
                                                id="document_issue_state"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: this.state
                                                        .document_issue_state,
                                                    onChange: event =>
                                                        this.change(
                                                            event,
                                                            'document_issue_state',
                                                            'length',
                                                            3
                                                        ),
                                                    type: 'text',
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} md={6}>
                                            {date_time_component}
                                        </GridItem>
                                    </GridContainer>

                                    <CustomInput
                                        success={
                                            this.state.nickname_state ===
                                            'success_' +
                                                this.state.document_category
                                                    .nickname
                                        }
                                        error={
                                            this.state.nickname_state ===
                                            'error_' +
                                                this.state.document_category
                                                    .nickname
                                        }
                                        labelText={this.handleLabeltextDocumentNickname(
                                            this.state.document_category.id
                                        )}
                                        id="nickname"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: this.state.nickname,
                                            onChange: event =>
                                                this.change(
                                                    event,
                                                    'nickname',
                                                    'length',
                                                    3
                                                ),
                                            type: 'text',
                                        }}
                                    />
                                    <div className={classes.formCategory}>
                                        <small>*</small> Required fields
                                    </div>
                                    {this.getDocumentHelp(
                                        this.state.document_category_id
                                    )}
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <Card>
                                <CardHeader>
                                    <h5>Document Upload</h5>
                                </CardHeader>
                                <CardBody>
                                    <React.Fragment>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12}>
                                                <IdentificationUploadContainer />
                                            </GridItem>
                                        </GridContainer>
                                    </React.Fragment>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <Button
                        color="primary"
                        disabled={!form_valid}
                        onClick={() => this.handleSubmitForm()}
                        className={classes.registerButton}
                    >
                        <FormattedMessage
                            id="newIdentification.submit"
                            defaultMessage={`Submit`}
                        />
                    </Button>
                    <Button
                        color="info"
                        onClick={() => this.handleCancel()}
                        className={classes.registerButton}
                    >
                        <FormattedMessage
                            id="newIdentification.cancel"
                            defaultMessage={`Cancel`}
                        />
                    </Button>
                </GridItem>
            </GridContainer>
        )
    }
}

IdentificationNew.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(IdentificationNew))
